import React, { useState, useRef } from "react";
import { Link as RLink } from "react-router-dom";
import axios from "axios";
import { API_URL, ReCAPTCHA_KEY } from "../../config.js";
import ReCAPTCHA from "react-google-recaptcha";


import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { SpinnerDotted } from "spinners-react"

import LogoImage from "assets/images/Logo/altair_logo.png";

import TiktokIconB from "../../assets/images/Hero/tiktok_blanco.png";
import TiktokIconN from "../../assets/images/Hero/tiktok_negro.png";

import { ReactComponent as TwitterIcon } from "feather-icons/dist/icons/twitter.svg";
import { ReactComponent as InstagramIcon } from "feather-icons/dist/icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "feather-icons/dist/icons/youtube.svg";
import { ReactComponent as FacebookIcon } from "feather-icons/dist/icons/facebook.svg";

import { ReactComponent as MailIcon } from "feather-icons/dist/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "feather-icons/dist/icons/phone.svg";
import { ReactComponent as SendIcon } from "feather-icons/dist/icons/send.svg";


const Container = tw.div`relative bg-gray-200 text-gray-700 px-1 md:px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center p-0 md:px-20 justify-center md:justify-between -mt-12`;
const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;
const ColumnHeading = tw.h5`uppercase font-bold`;
const Heading = tw.h1`uppercase font-extrabold text-5xl`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex m-auto`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-6xl rounded-full p-3 bg-gray-900 hover:bg-white text-white hover:text-black transition duration-300 mx-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const Icon = styled.span`
  ${tw`text-center items-center m-auto mt-10`}
  svg{
	  ${tw`w-16 h-16`}
  }
`

const FormContainer = tw.div`w-full flex-1 mt-8`;
const Form = tw.form`mx-auto`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-blue-500 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({ t, ReactPixel }) => {
	const recaptchaRef = useRef(null)
	const [white, setWhite] = useState(true)

	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const [sended, setSended] = useState(false)

	const [name, setName] = useState("")
	const [phone, setPhone] = useState("")
	const [email, setEmail] = useState("")
	const [message, setMessage] = useState("")

	const handleSend = async e => {
		e.preventDefault()
		setSended(false)

		const token = await recaptchaRef.current.executeAsync();
		recaptchaRef.current.reset();

		let _error = ""

		if (!name) _error = "Please enter a valid name";
		if (!phone) _error = "Please enter a valid phone";
		if (!email) _error = "Please enter a valid email";
		if (!message) _error = "Please enter a valid message";

		setError(_error)
		if (_error) return

		setLoading(true)

		const data = {
			token,
			name,
			phone,
			email,
			message
		};

		axios.post(`${API_URL}/email/send`, data)
			.then(res => {
				setLoading(false)
				if (res.data.error){
					setError(res.data.error)
					setSended(false)

				if (ReactPixel)
            ReactPixel.trackCustom('Contact', { email: email });

				}
				else {
					setError('')
					setSended(true)
				}
			})
			.catch(err => {
				setLoading(false)
				setSended(false)
				setError("Please try again")
			})
	}

	return (
		<Container id="footer">
			<Content>

				<div tw="text-center items-center mb-40 -mt-10 -mb-5">
					<Heading>{t("footer.contact")}</Heading>

					<div tw="flex flex-col">
						<div tw="flex flex-col lg:flex-row w-6/12 sm:m-auto!">
							<div tw="flex w-full">
								<Icon>
									<MailIcon tw="m-auto"></MailIcon>
								</Icon>
								<p style={{ marginTop: "50px", marginLeft: "20px", textAlign: "start" }}>{t("footer.mail")}: <br /> info@onegoodtravel.com</p>
							</div>
							<div tw="flex w-full">
								<Icon>
									<PhoneIcon tw="m-auto"></PhoneIcon>
								</Icon>
								<p style={{ marginTop: "50px", marginLeft: "20px", textAlign: "start" }}>{t("footer.phone")}: <br /> +1&nbsp;407-433-1950
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
							</div>
						</div>

						<div tw="flex w-8/12 text-center m-auto">
							<FormContainer>
								<Form onSubmit={handleSend}>
									<div tw="md:flex-row hidden md:flex">
										<div tw="flex w-1/2 mr-1">
											<Input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder={t("footer.i-fullname")} />
										</div>
										<div tw="flex w-1/2 ml-1">
											<Input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder={t("footer.i-phone")} />
										</div>
									</div>

									<div tw="md:hidden">
										<Input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder={t("footer.i-fullname")} />
										<Input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder={t("footer.i-phone")} />
									</div>

									<Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder={t("footer.i-mail")} />
									<TextArea value={message} onChange={(e) => setMessage(e.target.value)} type="text" placeholder={t("footer.i-message")} style={{ maxHeight: "130px", minHeight: "130px" }} />

									<ReCAPTCHA
										ref={recaptchaRef}
										sitekey={ReCAPTCHA_KEY}
										size="invisible"
									/>

									<p style={{ color: 'red', textAlign: 'center', marginTop: "10px" }}>{error}</p>
									<div tw="w-1/12 m-auto">
										<SpinnerDotted color="#4299e1" enabled={loading} />
									</div>
									{sended && <p style={{ color: '#4299e1', textAlign: 'center', marginTop: "10px" }}>The email has been sent successfully</p>}

									<SubmitButton type="submit">
										<SendIcon className="icon" />
										<span className="text">{t("footer.send")}</span>
									</SubmitButton>
								</Form>
							</FormContainer>
						</div>
						<div tw="flex items-center text-center mt-10">
							<SocialLinksContainer>
								<SocialLink target="blank" href="https://www.tiktok.com/@one.goodtravel?is_from_webapp=1&sender_device=pc" onMouseEnter={() => setWhite(false)} onMouseLeave={() => setWhite(true)}>
									{white && <img src={TiktokIconB} style={{ width: "18px" }} />}
									{!white && <img src={TiktokIconN} style={{ width: "18px" }} />}
								</SocialLink>
								<SocialLink target="blank" href="https://twitter.com/onegoodtravel">
									<TwitterIcon />
								</SocialLink>
								<SocialLink target="blank" href="https://www.instagram.com/one.goodtravel/">
									<InstagramIcon />
								</SocialLink>
								<SocialLink target="blank" href="https://youtube.com/channel/UCgfeL5pExMKw9YXNX4djQSQ">
									<YoutubeIcon />
								</SocialLink>
								<SocialLink target="blank" href="https://facebook.com/1goodtravel">
									<FacebookIcon />
								</SocialLink>
							</SocialLinksContainer>
						</div>
					</div>

				</div>


				<Divider />

				<SixColumns>
					<Column>
						<ColumnHeading>{t("footer.main")}</ColumnHeading>
						<LinkList>
							<LinkListItem>
								<Link href="#packages">{t("footer.package")}</Link>
							</LinkListItem>
							<LinkListItem>
								<Link href="#footer">{t("footer.service")}</Link>
							</LinkListItem>
							<LinkListItem>
								<Link href="/about">{t("footer.about")}</Link>
							</LinkListItem>
						</LinkList>
					</Column>
					<Column>
						<ColumnHeading>{t("footer.product")}</ColumnHeading>
						<LinkList>
							<LinkListItem>
								<Link href="/login">{t("footer.login")}</Link>
							</LinkListItem>
							<LinkListItem>
								<Link href="/register">{t("footer.register")}</Link>
							</LinkListItem>
							<LinkListItem>
								<Link href="/searchs">{t("footer.search")}</Link>
							</LinkListItem>
						</LinkList>
					</Column>
					<Column>
						<ColumnHeading>{t("footer.legal")}</ColumnHeading>
						<LinkList>
							<LinkListItem>
								<Link href="/term">{t("footer.privacy")}</Link>
							</LinkListItem>
							<LinkListItem>
								<Link href="/term">{t("footer.terms")}</Link>
							</LinkListItem>
						</LinkList>
					</Column>
				</SixColumns>

				<Divider />

				<ThreeColRow>
					<a target="blank" href="https://altaircode.com"><LogoContainer>
						<LogoImg src={LogoImage} style={{ width: "300px" }} />
						{/* <LogoText>Altair Code</LogoText> */}
					</LogoContainer></a>
					<CopywrightNotice>&copy; 2022 1goodtravel. {t("footer.rigth")}. <br /> {t("footer.D&D")} <a style={{ color: "black", fontWeight: "1000" }} target="blank" href="https://altaircode.com">Altair Code</a>.</CopywrightNotice>
				</ThreeColRow>
			</Content>
		</Container>
	);
};