import React, { useState, useEffect } from "react";
import "../../assets/js/Home.js"
import axios from "axios";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { API_URL } from "../../config.js";
import { useTranslation } from "react-i18next"


import Header from "./Header.js";
import Hero from "./Hero.js";
import Search from './Search.js'
import Packages from "./Packages.js";
import Footer from "../Components/Footer.js";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'





export default () => {
	const [t, i18n] = useTranslation("global")
	
	const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init('335539945398054', options);
    ReactPixel.pageView();

	const tagManagerArgs = {
		gtmId: 'GTM-5TXGRT9',
		events: {
			sendUserInfo:'userInfo'
		}
	}
	TagManager.initialize(tagManagerArgs)


	return (
		<> <MessengerCustomerChat
		pageId="104329355547288"
		appId="432514958710935"
		 
		/>
			<Header t={t} i18n={i18n} />
			<Hero t={t} />
			<Search t={t} />
			<Packages t={t} i18n={i18n}/>
			<Footer t={t} ReactPixel={ReactPixel}/>
		</>
	);
}
