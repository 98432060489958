import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config.js";
import { Redirect, Link } from "react-router-dom"

//CSS PAckages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";


//Img Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

//Icons
import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check.svg";

//Components
import Checkout from "./Checkout";
import { SectionHeading } from "../Components/Headings";

import OrlandoIMG from "assets/images/Tourist Packages/Orlando/6.jpg"

import { getCurrentUser } from "../../services/account";

//Icons
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { ReactComponent as DollarIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const SubmitButton = styled.button `
  ${tw`mt-5 tracking-wide font-semibold bg-blue-500 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

//CSS Classes
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)
	`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
		props.featured &&
		css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`flex flex-col bg-gray-100 rounded-lg border`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-blue-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2  after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 transition duration-300`;
const Description = tw.div``;

const Columns = tw.div`flex flex-col bg-white w-full`
const TwoColumn = tw.div`flex flex-col md:flex-row bg-white w-full`;
const Column = tw.div`flex w-full md:w-1/2 text-left`;
const CentralColumn = tw.div`flex w-3/4 items-center m-auto`;
const Details = tw.div`w-full p-10`;
const Questions = tw.div`w-full p-10 items-center text-center`;
const Question = tw.div`w-full p-10 items-center text-center bg-gray-100 rounded-lg border`;

export default ({ element, t, recaptchaRef, ReactPixel }) => {

	//Logged Accout
	const [user, setUser] = useState("");
	useEffect(async () => {
		//Get logged accout
		async function getUser() {
			const _user = await getCurrentUser();
			setUser(_user);
		}
		getUser();
	}, []);

	//Given a package returns the range of avaliable dates
	const getFormatDate = p => {

		const startUTC = new Date(p.startDate);
		const startNormal = startUTC.toISOString();
		const start = new Date(startNormal.substring(0, startNormal.length - 1));

		const endUTC = new Date(p.endDate);
		const endNormal = endUTC.toISOString();
		const end = new Date(endNormal.substring(0, endNormal.length - 1));



		const startM = start.toLocaleString("en-US", { month: "short" })
		const endM = start.toLocaleString("en-US", { month: "short" })


		const startR = startM + " " + start.getDate() + ", " + start.getFullYear();
		const endR = endM + " " + end.getDate() + ", " + end.getFullYear();

		return startR + " - " + endR
	}
	const [checkout, setCheckout] = useState(false)

	return (
		<>

			<HeadingRow style={{marginTop:"150px"}}>
				{element && <Heading>{element.name}</Heading>}
				{!element && <Heading>{t("details.loading")}...</Heading>}
			</HeadingRow>

			{user && checkout && <Posts>
				<Checkout element={element} t={t} recaptchaRef={recaptchaRef} ReactPixel={ReactPixel} />
			</Posts>}


			{element && !checkout && <Posts>
				<PostContainer featured={true}>
					<Post className="group" as="a" href="">
						<Image imageSrc={element.image ? `https://1goodtravel.s3.amazonaws.com/${element.image}` : OrlandoIMG} />
						<Info>
							<Category>{element.type}</Category>
							<CreationDate>{getFormatDate(element)}</CreationDate>
							<Title>{element.numberDays} {t("details.night")}</Title>
							<Category>${element.price}</Category>
							<Description>{element.description}</Description>

						</Info>

					</Post>
				</PostContainer>

				<PostContainer featured={true} style={{ marginBottom: "20px" }}>
					<Heading>{t("details.details")}</Heading>
					<br />
					<Post style={{ backgroundColor: "white" }}>
						<Columns>
							<TwoColumn>
								<Column>
									<Details>
										<Title style={{ marginBottom: "20px" }}>{t("details.included")}</Title>
										{element.includes.map(include => (
											<div style={{ margin: "10px" }}>
												<CheckIcon />
												<p style={{ marginTop: "-25px", marginLeft: "50px" }}> {include}</p>
											</div>
										))}
									</Details>
								</Column>
								<Column>
									<Questions>
										<Question>
											<Title>{t("details.question")}</Title>
											<p>{t("details.call")}</p>
											<br />
											<span style={{ color: "black", fontSize: "20px" }}>+1 407-433-1950</span>

											<div style={{ marginTop: "20px" }}>
												<p>{t("details.write")}</p>
												<span style={{ color: "black", fontSize: "20px" }}>info@onegoodtravel.com</span>
											</div>
										</Question>
									</Questions>
								</Column>
							</TwoColumn>
							<TwoColumn>
								<CentralColumn>
									<Details>
										<Title style={{ marginBottom: "20px", textAlign: "center" }}>{t("details.images")}</Title>
										 <Carousel showArrows={true}>
											{element.images.map(img => (
												<div>
													<img src={`https://1goodtravel.s3.amazonaws.com/${img}`} />
												</div>
											))}
										</Carousel> 
									</Details>
								</CentralColumn>
							</TwoColumn>
							<TwoColumn>
								{!user && <Details>
									<Link to="/login"><SubmitButton>
										<LoginIcon className="icon" />
										<span className="text">{t("details.login")}</span>
									</SubmitButton></Link>
									<p tw="text-center m-1">{t("details.login-text")}</p>
								</Details>}

								{user && <Details>
									<SubmitButton onClick={() => setCheckout(true)}>
										<DollarIcon className="icon" />
										<span className="text">{t("details.checkout")}</span>
									</SubmitButton>
								</Details>}
							</TwoColumn>
						</Columns>
					</Post>
				</PostContainer>
			</Posts>}
		</>
	);
};