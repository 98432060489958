import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom"
import axios from "axios";
import { API_URL } from "../../config.js";

//CSS Pckages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

//Components
import AnimationRevealPage from "../Components/AnimationRevealPage.js";
import { Container as ContainerBase } from "../Components/Layouts";
import illustration from "assets/images/signup-illustration.svg";


import logo from "assets/images/Logo/last1.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";

//CSS Classes
const Container = tw(ContainerBase)
`min-h-screen bg-blue-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div `max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div `lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a ``;
const LogoImage = tw.img `h-20 mx-auto`;
const MainContent = tw.div `mt-12 flex flex-col items-center`;
const Heading = tw.h1 `text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div `w-full flex-1 mt-8`;

const Form = tw.form `mx-auto max-w-xs`;
const Input = tw.input `w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button `
  ${tw`mt-5 tracking-wide font-semibold bg-blue-500 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-blue-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;


export default (props) => {
    const [access, setAccess] = useState(false);
    const [error, setError] = useState(false);
    const [token, setToken] = useState();

    const [accountError, setAccountError] = useState(false)
    const [contactRedirect, setContactRedirect] = useState(false)


    const handleAccess = e => {
        e.preventDefault()
        if(accountError)
            setContactRedirect(true)
        if (!error && !accountError && token){
            localStorage.setItem('1good_travel_account_token', token)
            setAccess(true)
        }
    }

    useEffect(async () => { 
        const search = props.history.location.search
        if (search) {
            try {
                const line = search.split("?");
                const params = line[1].split("&")
                const val = params[0].split("=")
                const account = params[1].split("=")

                const valName = val[0]
                const valValue = val[1]

                if (valName != "id")
                    setError(true)

                const accountName = account[0]
                const accountValue = account[1]

                if (accountName != "accountId")
                    setError(true)

                const result = await axios.post(`${API_URL}/account/validate`, { accountId: accountValue, validationId: valValue });

                if (result.data.error)
                    setAccountError(true)
                else
                    setToken(result.data.token)
            }
            catch (err) {
                setError(true)
            }
        }
        else
            setError(true)
    }, []);

    if(contactRedirect || error || access ) return <Redirect to="/"/>

    return (
        <AnimationRevealPage>
            <Container>
                <Content>
                    <MainContainer>
                        <LogoLink href="/">
                            <LogoImage src={logo} />
                        </LogoLink>
                        <MainContent tw="mt-40 text-center">
                            <Heading>Validated 1Good Travel Account</Heading>
                            <FormContainer>
                                <Form onSubmit={handleAccess}>
                                    {!accountError && <p>Your ONE GOOD TRAVEL account has been successfully. To access our platform press the following button</p>}
                                    {accountError && <p>Your validation period has expired or an error has occurred whit your account. Please contact us</p>}
                                    <SubmitButton type="submit">
                                        <LoginIcon className="icon" />
                                        {!accountError && <span className="text">Access</span>}
                                        {accountError && <span className="text">Contact US</span>}
                                    </SubmitButton>
                                </Form>
                            </FormContainer>
                        </MainContent>
                    </MainContainer>
                    <IllustrationContainer>
                        <IllustrationImage imageSrc={illustration} />
                    </IllustrationContainer>
                </Content>
            </Container>
        </AnimationRevealPage>
    );
}