import React from "react";
import { useTranslation } from "react-i18next"

//CSS Packages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

//Componets
import { SectionHeading } from "../Components/Headings.js";
import AnimationRevealPage from "../Components/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../Components/Layouts.js";

import Header from "./Header";
import Footer from "../Components/Footer.js";

//CSS Classes
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)
	`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default () => {
	const [t, i18n] = useTranslation("global")
	return (
		<AnimationRevealPage>
			<Header t={t} i18n={i18n} />
			<Container style={{paddingTop: "80px"}}>
				<ContentWithPaddingXl >
					<HeadingRow>
						<Heading>{t("terms.title")}</Heading>
					</HeadingRow>
					<Text>
						<p>{t("terms.main.text-1")}</p>
						<p>{t("terms.main.text-2")}</p>

						<h1>{t("terms.1.title")}</h1>
						<br />
						<p>{t("terms.1.text")}</p>

						<h1>{t("terms.2.title")}</h1>
						<br />
						<p>{t("terms.2.text-1")}</p>
						<p>{t("terms.2.text-2")}</p>


						<h1>{t("terms.3.title")}</h1>
						<br />
						<p>{t("terms.3.text-1")}</p>
						<p>{t("terms.3.text-2")}</p>

						<h1>{t("terms.4.title")}</h1>
						<br />
						<p>{t("terms.4.text-1")}</p>
						<p>{t("terms.4.text-2")}</p>

						<h1>{t("terms.5.title")}</h1>
						<br />
						<p>{t("terms.5.text")}</p>
						<br />
						<ul>
							<li><p>{t("terms.5.li-1")}</p></li>
							<li><p>{t("terms.5.li-2")}</p></li>
							<li><p>{t("terms.5.li-3")}</p></li>
							<li><p>{t("terms.5.li-4")}</p></li>
							<li><p>{t("terms.5.li-5")}</p></li>
							<li><p>{t("terms.5.li-6")}</p></li>
							<li><p>{t("terms.5.li-7")}</p></li>
							<li><p>{t("terms.5.li-8")}</p></li>
						</ul>

						<h1>{t("terms.6.title")}</h1>
						<br />
						<ul>
							<li><p>{t("terms.6.li-1")}</p></li>
							<li><p>{t("terms.6.li-2")}</p></li>
							<li><p>{t("terms.6.li-3")}</p></li>
						</ul>

						<h1>{t("terms.7.title")}</h1>
						<br />
						<p>{t("terms.7.text")}</p>

						<h1>{t("terms.8.title")}</h1>
						<br />
						<ul>
							<li><p>{t("terms.8.li-1")}</p></li>
							<li><p>{t("terms.8.li-2")}</p></li>
						</ul>

						<h1>{t("terms.9.title")}</h1>
						<br />
						<ul>
							<li><p>{t("terms.9.li-1")}</p></li>
							<li><p>{t("terms.9.li-2")}</p></li>
							<li><p>{t("terms.9.li-3")}</p></li>
							<li><p>{t("terms.9.li-4")}</p></li>
							<li><p>{t("terms.9.li-5")}</p></li>
							<li><p>{t("terms.9.li-6")}</p></li>
						</ul>


						<h1>{t("terms.10.title")}</h1>
						<br />
						<p><strong>1)</strong> {t("terms.10.1")}</p>
						<p><strong>2)</strong> {t("terms.10.2")}</p>
						<p><strong>3)</strong> {t("terms.10.3")}</p>
						<p><strong>4)</strong> {t("terms.10.4")}</p>
						<p><strong>5)</strong> {t("terms.10.5")}</p>
						<p><strong>6)</strong> {t("terms.10.6")}</p>
						<p><strong>7)</strong> {t("terms.10.7")}</p>
						<p><strong>8)</strong> {t("terms.10.8")}</p>



						<p><b>{t("terms.text-1")}</b></p>
						<p><b>{t("terms.text-2")}</b></p>

						<br />
						<p><b>{t("terms.link-1")}</b></p>
						<p><b>{t("terms.link-2")}</b></p>
						<p><b>{t("terms.link-3")}</b></p>


						<h1>{t("terms.update.title")}</h1>
						<p>{t("terms.update.text")}</p>
						<br />

						<p style={{ color: "gray" }}>{t("terms.update.l-1")}</p>
						<p style={{ color: "gray" }}>{t("terms.update.l-2")}</p>

						<br />

						<p>{t("terms.update.date")}</p>
						<p>{t("terms.update.end")}</p>

					</Text>
				</ContentWithPaddingXl>
			</Container>
			<Footer t={t} />
		</AnimationRevealPage>
	);
};