import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config.js";
import { useTranslation } from "react-i18next"

//Componets
import { Container, ContentWithPaddingXl } from "../Components/Layouts";

import Header from "./Header";
import SubHeader from "./SubHeader";
import Packages from "./Packages"
import Footer from "../Components/Footer.js";

export default (props) => {

    //Package list and packages to show
    const [packages, setPackages] = useState([]);
    const [filtered, setFiltered] = useState([]);

    //Searchs inputs
    const [location, setLocation] = useState()
    const [start, setStart] = useState(new Date())
    const [end, setEnd] = useState(new Date())

    useEffect(async () => {

        //Get all pacjkages
        const _packages = await getPackages();
        setPackages(_packages)
        setFiltered(filter(_packages))

        //Get location, start date and end date that is sent by url
        const search = props.history.location.search
        if (search) {
            try {
                const line = search.split("?");
                const params = line[1].split("&")

                params.forEach(p => {
                    const value = p.split("=")

                    if (value[0] == "location") setLocation(value[1])
                    if (value[0] == "start") setStart(new Date(value[1].replace(/%20/g, ' ')))
                    if (value[0] == "end") setEnd(new Date(value[1].replace(/%20/g, ' ')))

                });
            }
            catch (err) {
                setLocation()
                setStart()
                setEnd()
            }
        }
        else {
            setLocation()
            setStart(new Date())
            setEnd(new Date())
        }
    }, []);

    async function getPackages() {
        const result = await axios.get(`${API_URL}/package/getAll`);
        return result.data.packages
    }

    const setSearch = () => {
        setFiltered(filter(packages))
    }

    //Index of by Id
    const index_of = (array, element) => {
        for (let i = 0; i < array.length; i++) {
            if (element._id == array[i]._id)
                return i;
        }
    }


    const filter = (_packages) => {

        if (!location && !start && !end) return _packages

        //Filter to name, location or description
        let _locations = []
        if (location) {
            _locations = _packages.filter(p => p.name.toLowerCase().indexOf(location.toLowerCase()) >= 0
                || p.type.toLowerCase().indexOf(location.toLowerCase()) >= 0
                || p.description.toLowerCase().indexOf(location.toLowerCase()) >= 0);
        }

        //Filter to start date
        let _starts = []
        if (start) {

            _starts = _packages.filter(p => {
                const startUTC1 = new Date(start);
                const startNormal1 = startUTC1.toISOString();
                const start1 = new Date(startNormal1.substring(0, startNormal1.length - 1));

                const startUTC2 = new Date(p.startDate);
                const startNormal2 = startUTC2.toISOString();
                const start2 = new Date(startNormal2.substring(0, startNormal2.length - 1));

                return start1.getDate() == start2.getDate() && start1.getMonth() == start2.getMonth() && start1.getFullYear() == start2.getFullYear();
            })
        }

        //Filter to end date
        let _ends = []
        if (end) {

            _ends = _packages.filter(p => {
                const endUTC1 = new Date(end);
                const endNormal1 = endUTC1.toISOString();
                const end1 = new Date(endNormal1.substring(0, endNormal1.length - 1));

                const endUTC2 = new Date(p.endDate);
                const endNormal2 = endUTC2.toISOString();
                const end2 = new Date(endNormal2.substring(0, endNormal2.length - 1));

                return end1.getDate() == end2.getDate() && end1.getMonth() == end2.getMonth() && end1.getFullYear() == end2.getFullYear();
            })
        }

        //Remove repeated
        let result = _locations.concat(_starts).concat(_ends)
        result = result.filter((p, i) => {

            return index_of(result, p) == i
        })
        return result
    }
    
    const [t, i18n] = useTranslation("global")
    return (
        <>
        <Container>
            <Header t={t} i18n={i18n}/>

            
            <SubHeader   setSearch={setSearch} location={location} setLocation={setLocation} start={start} setStart={setStart} end={end} setEnd={setEnd} t={t}/>
            <Packages filtered={filtered} t={t}/>
            <Footer t={t}/>
        </Container>
        </>
    );
}