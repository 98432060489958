import React, { useState, useEffect } from "react";

//CSS Packages
import AnimationRevealPage from "../Components/AnimationRevealPage.js";
import { Container as ContainerBase } from "../Components/Layouts";
import tw from "twin.macro";


import logo from "assets/images/Logo/last1.png";

//Components
import MobileHeader from "./MobileHeader.js"
import Header from "./Header.js"
import User from "./User.js";
import Package from "./Package.js";


//CSS Classes
const Container = tw(ContainerBase)`bg-white lg:bg-blue-900 text-white font-medium justify-center -m-8 p-0 flex hidden md:flex md:min-h-screen`;
const Content = tw.div`m-0 p-0 sm:mx-20 sm:my-16 bg-white text-gray-900 sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12 p-10`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-20 mx-auto`;
const MainContent = tw.div`mt-12 items-center`;
const FormContainer = tw.div``;
const Card = tw.div`p-4 pt-10 m-0 bg-gray-200 w-full lg:w-3/4 text-gray-900 shadow sm:rounded-lg items-center m-auto`;
const Mobile = tw.div`flex md:hidden`;


// function:
//		true => change rendering (only in manager page)
// link: 
//		true => reditect to another page
//		false => scroll in this page
//
// lo: 
//		direction to scroll or redirect. If function is true => Mode to render
// name:
//		link text
const links = [
    {
        function: true,
        link: false,
        to: "User",
        name: "Users"
    },
    {
        function: true,
        link: false,
        to: "Package",
        name: "Packages"
    },
];

export default () => {

    useEffect(() => {
        links.forEach(l => {
            l.function = setMode
        });
    }, []);

    const [mode, setMode] = useState("User")
    return (
        <AnimationRevealPage>
            <Container>
                <Content>
                    <MainContainer style={{ width: "100%" }}>
                        <LogoLink href={"/"}>
                            <LogoImage src={logo} />
                        </LogoLink>
                        <MainContent>
                            <Header mode={mode} setMode={setMode} />
                            <FormContainer>
                                <Card style={{ textAlign: "center" }}>
                                    {mode == "User" && <User />}
                                    {mode == "Package" && <Package />}
                                </Card>
                            </FormContainer>
                        </MainContent>
                    </MainContainer>
                </Content>
            </Container>

            <MobileHeader links={links} />
            <Mobile>
                <Card style={{ textAlign: "center", marginTop: "120px" }}>
                    {mode == "User" && <User />}
                    {mode == "Package" && <Package />}
                </Card>
            </Mobile>

        </AnimationRevealPage>
    );
}