import React from "react";
import { Link } from "react-router-dom"

//CSS Package
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

//Components
import { Container, ContentWithPaddingXl } from "../Components/Layouts.js";
import { PrimaryButton as PrimaryButtonBase } from "../Components/Buttons.js";
import { SectionHeading } from "../Components/Headings.js";

//Icons
import { ReactComponent as CalendarIcon } from "feather-icons/dist/icons/calendar.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";


import OrlandoIMG from "../../assets/images/Tourist Packages/Orlando/6.jpg";

//CSS Classes
const Content = tw.div `py-20 lg:py-24`;

const TabContent = tw.div `mt-6 flex flex-wrap flex-col md:flex-row sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div `mt-10 w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12 m-auto`;
const Card = tw.div `mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs mb-20`;
const CardImage = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw `h-64 bg-cover bg-center rounded`
]);
const CardText = tw.div `mt-4`;
const CardHeader = tw.div `flex justify-between items-center`;
const CardType = tw.div `text-blue-700 font-bold text-lg`;
const CardPrice = tw.div `font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span `font-bold text-gray-800 text-lg`;
const CardTitle = tw.h5 `text-xl mt-4 font-bold`;
const CardMeta = styled.div `
${tw`flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;
const CardMetaFeature = styled.div`
${tw`flex items-center mt-4`}
svg {
    ${tw`w-5 h-5 mr-1`}
  }
  `;
const CardAction = tw(PrimaryButtonBase)`w-full mt-8`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
// const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
//   ${tw`inline-block mt-4 text-sm font-semibold`}
//   `

export default ({ filtered, t }) => {

    const getFormatDate = p => {

        const startUTC = new Date(p.startDate);
        const startNormal = startUTC.toISOString();
        const start = new Date(startNormal.substring(0, startNormal.length - 1));

        const endUTC = new Date(p.endDate);
        const endNormal = endUTC.toISOString();
        const end = new Date(endNormal.substring(0, endNormal.length - 1));



        const startM = start.toLocaleString("en-US", { month: "short" })
        const endM = start.toLocaleString("en-US", { month: "short" })


        const startR = startM + " " + start.getDate() + ", " + start.getFullYear();
        const endR = endM + " " + end.getDate() + ", " + end.getFullYear();

        return startR + " - " + endR
    }

    return (
        <Container>
            <ContentWithPaddingXl>
                <TabContent>
                    {filtered.map((p, index) => (
                        <CardContainer>
                            <Card>
                                <CardImage imageSrc={p.image ? `https://1goodtravel.s3.amazonaws.com/${p.image}` : OrlandoIMG} />
                                <CardText>
                                    <CardHeader>
                                        <CardType>{p.name}</CardType>
                                        <CardPrice>
                                            <CardPriceAmount>{p.price}</CardPriceAmount>
                                        </CardPrice>
                                    </CardHeader>
                                    <CardMeta>
                                        <CardMetaFeature>
                                            <TimeIcon /> {p.numberDays} {t("searchs.night")}
                                        </CardMetaFeature>
                                        <CardMetaFeature>
                                            <CalendarIcon /> {getFormatDate(p)}
                                        </CardMetaFeature>
                                    </CardMeta>
                                    <Description style={{ textAlign: "start" }}>{p.description.substring(0,47)}....</Description>
                                    <CardAction><Link to={`/details?package=${p._id}`} style={{ cursor: "pointer" }}>{t("searchs.details")}</Link></CardAction>
                                </CardText>
                            </Card>
                        </CardContainer>
                    ))}
                </TabContent>
            </ContentWithPaddingXl>
        </Container>
    );
};