const $ = require('./jquery.js');

// Smooth scroll for the navigation menu and links with .scrollto classes
var scrolltoOffset = $('#header').outerHeight() - 21;

// Activate smooth scroll on page load with hash links in the url
$(document).ready(function() {
    if (window.window.location.hash) {
        var initial_nav = window.window.location.hash;
        if ($(initial_nav).length) {
            var scrollto = $(initial_nav).offset().top - scrolltoOffset;
            $('html, body').animate({
                scrollTop: scrollto
            }, 1500, 'easeInOutExpo');
        }
    }
});


// Toggle .header-scrolled class to #header when page is scrolled
$(window).scroll(function() {
    if ($(this).scrollTop() > 300) {
        $('#header').addClass('header-scrolled');
    } else {
        $('#header').removeClass('header-scrolled');
    }
});

if ($(window).scrollTop() > 100) {
    $('#header').addClass('header-scrolled');
}
