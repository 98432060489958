import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../config.js";
import { Redirect, Link } from "react-router-dom"


import { SpinnerDotted } from "spinners-react"
import { SectionHeading } from "../Components/Headings";

//Get all countries code
import { getCountries, getCode } from "iso-3166-1-alpha-2";
//Get logged account
import { getCurrentUser } from "../../services/account";

//CSS Packages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

//Select for country
import Select from 'react-select';

//Icons
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import { ReactComponent as DollarIcon } from "feather-icons/dist/icons/dollar-sign.svg";

//CSS Classes

const HeadingRow = tw.div`flex items-center! text-center! w-full`;
const Heading = tw(SectionHeading)
    `text-gray-900 text-3xl w-full flex items-center! text-center!`;

const Columns = tw.div`flex flex-col md:flex-row bg-white w-full`;
const Column = tw.div`flex w-full items-center m-auto`;


const TwoColumn = tw.div`flex flex-col md:flex-row bg-white w-full`;
const CentralColumn = tw.div`flex w-1/2 items-center m-auto`;
const Details = tw.div`w-full p-10`;

const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white m-1`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-blue-500 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;


export default ({ element, t, ReactPixel }) => {
    useEffect(async () => {
        //Get all countries code
        const countries = getCountries();
        const _options = []
        countries.forEach(c => {
            _options.push({
                value: getCode(c),
                label: c
            });
        });
        setOptions(_options);

        //Get all mounths
        const _months = []
        for (let i = 1; i <= 12; i++) {
            _months.push({ value: i, label: i })
        }
        setMOptions(_months)

        //Get all years
        const _years = []
        for (let i = 2022; i <= 3000; i++) {
            _years.push({ value: i, label: i })
        }
        setYOptions(_years)


    }, []);


    //Checout Inputs
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [zip, setZip] = useState("");
    const [card, setCard] = useState("");
    const [exp_m, setExp_m] = useState("");
    const [exp_y, setExp_y] = useState("");
    const [cvc, setCVC] = useState("");

    //Checkout error ands success
    const [Perror, setPError] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    //Country, Mounth and Year
    const [options, setOptions] = useState()
    const [Moptions, setMOptions] = useState()
    const [Yoptions, setYOptions] = useState()

    //Checout
    const handlePay = async (e) => {
        e.preventDefault();

        for (let i = 0; i < persons.length; i++) {
            const p = persons[i];
            if (p["name"] == "" || p["lastName"] == "" || p["country"] == "") {
                setPError("Please fill all fields")
                return;
            }
        }

        if (!name || !address || !city || !country || !zip || !card || !exp_m || !exp_y || !cvc) {
            setPError("Please fill all fields")
            return;
        }

        setPError("")
        setSuccess(false)
        setLoading(true)
        axios.post(`${API_URL}/payment/pay`,
            {
                persons: persons,
                packageId: element._id,
                name: name,
                address: address,
                city: city,
                country: country.value,
                zip: zip,
                card: card,
                exp_m: exp_m.value,
                exp_y: exp_y.value,
                cvc: cvc,
            }, {
            headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
        }).
            then(res => {
                setLoading(false)
                if (res.data.error) {
                    setSuccess(false)
                    setPError(res.data.error)

                    if (ReactPixel)
                        ReactPixel.trackCustom('Checkout', {
                            PackageName: element.name,
                            NameOnTheCard: name,
                            address: address,
                            city: city,
                            country: country.value
                        });

                }
                else {
                    setSuccess(true)
                    setPError("")
                }
            }).
            catch(err => setPError("Please try again"));
    }

    //Change country, mounth and year
    const handleChange = (option) => {
        setCountry(option)
    }
    const handleMChange = (option) => {
        setExp_m(option)
    }
    const handleYChange = (option) => {
        setExp_y(option)
    }


    const [amountPersons, setAmountPersons] = useState(1)
    const [persons, setPersons] = useState([{ name: "", middleName: "", lastName: "", country: "" }])
    const handleChangeAmount = (e) => {
        let number;
        try {
            number = Number(e.target.value)
        } catch (error) {
            return
        }
        if (number <= 0) return

        setAmountPersons(number)
        const _person = []
        for (let i = 0; i < number; i++) _person.push({ name: "", middleName: "", lastName: "", country: "" })
        setPersons(_person)
    }


    return (

        <Details>


            <div tw="w-5/6">
                <HeadingRow>
                    <Heading>{t("checkout.personal")}</Heading>
                </HeadingRow>

                <FormContainer>
                    <Form>
                        <p tw="text-center">{t("checkout.amount")}</p>
                        <div tw="flex flex-row">
                            <div tw="w-2/3">
                                <Input type="number" onChange={handleChangeAmount} value={amountPersons} />
                            </div>
                            <div tw="w-1/3">
                                <p tw="text-center">${amountPersons * element.price}</p>
                            </div>
                        </div>
                        <div tw="block md:hidden">
                            {persons.map((v, i) => (
                                <div tw="w-full m-auto mt-10">
                                    <Input onChange={(e) => persons[i]["name"] = e.target.value} value={persons["name"]} type="text" placeholder={t("checkout.name")} />
                                    <Input onChange={(e) => persons[i]["middleName"] = e.target.value} value={persons["middleName"]} type="text" placeholder={t("checkout.middleName")} />
                                    <Input onChange={(e) => persons[i]["lastName"] = e.target.value} value={persons["lastName"]} type="text" placeholder={t("checkout.lastName")} />
                                    <Input onChange={(e) => persons[i]["country"] = e.target.value} value={persons["country"]} type="text" placeholder={t("checkout.country")} />
                                </div>
                            ))
                            }
                        </div>
                        <div tw="hidden md:flex md:flex-col">
                            {persons.map((v, i) => (
                                <div tw="flex w-full m-auto">
                                    <Input onChange={(e) => persons[i]["name"] = e.target.value} value={persons["name"]} type="text" placeholder={t("checkout.name")}/>
                                    <Input onChange={(e) => persons[i]["middleName"] = e.target.value} value={persons["middleName"]} type="text" placeholder={t("checkout.middleName")} />
                                    <Input onChange={(e) => persons[i]["lastName"] = e.target.value} value={persons["lastName"]} type="text" placeholder={t("checkout.lastName")} />
                                    <Input onChange={(e) => persons[i]["country"] = e.target.value} value={persons["country"]} type="text" placeholder={t("checkout.country")}/>
                                </div>
                            ))
                            }
                        </div>
                    </Form>
                </FormContainer>
            </div>
            <div tw="w-5/6" style={{ marginTop: "100px" }}>
                <HeadingRow>
                    <Heading>{t("checkout.billing")}</Heading>
                </HeadingRow>


                <TwoColumn>
                    <div tw="w-full md:w-1/2 mt-1">
                        <Input value={address} onChange={(e) => setAddress(e.target.value)} type="text" placeholder={t("details.i-address")} />
                        <Input value={city} onChange={(e) => setCity(e.target.value)} type="text" placeholder={t("details.i-city")} />
                        <Input value={zip} onChange={(e) => setZip(e.target.value)} type="text" placeholder={t("details.i-pc")} />
                    </div>
                    <div tw="w-full md:w-1/2 ml-0 md:ml-1 mt-1">
                        <Input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder={t("details.i-name")} />

                        <div tw="flex flex-row mt-1">
                            <div tw="w-full md:w-1/2">
                                <Select
                                    styles={{
                                        control: (provider, _) => ({
                                            ...provider,
                                            height: 54,
                                            marginLeft: 5,
                                            marginBottom: 5,
                                            backgroundColor: '#f7fafc',
                                            border: '1px solid #edf2f7',
                                            borderRadius: 5
                                        })
                                    }}
                                    placeholder={t("details.i-expM")}
                                    onChange={handleMChange}
                                    options={Moptions}
                                    isSearchable={true}
                                />
                            </div>
                            <div tw="w-full md:w-1/2">
                                <Select
                                    styles={{
                                        control: (provider, state) => ({
                                            ...provider,

                                            height: 54,
                                            marginLeft: 5,
                                            marginBottom: 5,
                                            backgroundColor: '#f7fafc',
                                            border: '1px solid #edf2f7',
                                            borderRadius: 5
                                        })
                                    }}
                                    placeholder={t("details.i-expY")}
                                    onChange={handleYChange}
                                    options={Yoptions}
                                    isSearchable={true}
                                />
                            </div>
                        </div>

                        <Input value={cvc} onChange={(e) => setCVC(e.target.value)} type="text" placeholder={t("details.i-cvc")} />

                    </div>
                </TwoColumn>

                <Select
                    styles={{
                        control: (provider, _) => ({
                            ...provider,
                            height: 54,
                            marginLeft: 6,
                            marginBottom: 3,
                            marginTop: 3,
                            backgroundColor: '#f7fafc',
                            border: '1px solid #edf2f7',
                            borderRadius: 5,
                        })
                    }}
                    placeholder={t("details.i-country")}
                    onChange={handleChange}
                    options={options}
                    isSearchable={true}
                />
                <Input value={card} onChange={(e) => setCard(e.target.value)} type="text" placeholder={t("details.card")} />

                <p style={{ color: 'red', textAlign: 'center', marginTop: "10px" }}>{Perror}</p>
                <div tw="w-1/12 m-auto">
                    <SpinnerDotted color="#4299e1" enabled={loading} />
                </div>
                {success && <p style={{ color: '#1a202c', textAlign: 'center', marginTop: "10px" }}>{t("details.success")}</p>}

                <SubmitButton onClick={handlePay}>
                    <DollarIcon className="icon" />
                    <span className="text">{t("details.checkout")}</span>
                </SubmitButton>

            </div>

        </Details>
    );
};