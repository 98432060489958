import React,{ forwardRef, useState } from "react";

import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next"

//CSS Packages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

//Date type input
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//CSS Classes
const Container = tw.div `text-black p-10`;
const Content = tw.div `m-auto mt-20 md:mt-0`;

const Form = tw.form ``;
const Input = tw.input `w-full cursor-pointer px-8 py-4 font-medium border bg-white text-black placeholder-gray-900 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`;
const SubmitButton = styled.button `
  ${tw`mt-5 tracking-wide font-semibold bg-blue-700 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Heading = styled.h1`
  ${tw`text-3xl font-black border-white rounded-full leading-snug sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
        tw="w-full text-left cursor-pointer px-8 py-4 font-medium border bg-white text-black placeholder-gray-900 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
        onClick={(e) => {
            e.preventDefault()
            onClick(e)
        }} ref={ref}>{value}</button>
))

export default ({t}) => {

    //Searchs inputs
    const [location, setLocation] = useState()
    const [start, setStart] = useState(new Date())
    const [end, setEnd] = useState(new Date())

    //Redirect and url
    const [redirect, setRedirect] = useState(false);
    const [url, setUrl] = useState();

    //Redirect to searchs
    const search = (e) => {
        e.preventDefault();

        //Add fields in the Url to redirect to Searchs
        let _url = "/searchs"
        if (location || start || end) _url += "?"

        _url += location ? "location=" + location : ""
        _url += start ? ((location ? "&" : "") + "start=" + start) : ""
        _url += end ? ((location || start ? "&" : "") + "end=" + end) : ""

        setUrl(_url)
        setRedirect(true)
    }
    

    if (redirect) return <Redirect to={url} />
    return (
        <Container id="searchs" style={{paddingTop:"100px"}}>
            
            <Content>
                <Heading>{t("search-panel.search")}</Heading>
                <p>{t("search-panel.text")}</p>
                <br />
                <div style={{ backgroundColor: "#ecc94b", padding: "5px" }}>
                    <Form>
                        <div tw="w-full flex flex-col md:flex-row">
                            <div tw="w-full md:w-3/5 flex z-20">
                                <Input value={location} onChange={(e) => setLocation(e.target.value)} type="text" placeholder={t("search-panel.input")} />
                            </div>
                            <div tw="w-full md:w-1/5 flex z-30">
                                <DatePicker
                                    selected={start}
                                    onChange={(d) => setStart(d)}
                                    customInput={<CustomInput />}
                                />
                            </div>
                            <div tw="w-full md:w-1/5 flex z-20">
                                <DatePicker
                                    selected={end}
                                    onChange={(d) => setEnd(d)}
                                    customInput={<CustomInput />}
                                />
                            </div>
                        </div>
                        <div tw="flex w-full -mt-4">
                            <SubmitButton tw="z-10" onClick={search}>{t("search-panel.search")}</SubmitButton>
                        </div>
                    </Form>
                    
                </div>
            </Content>
            
        </Container>
        
        
    );
        
};