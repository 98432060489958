import React, { useEffect, useState } from "react";
import { Link as LinkScroll } from 'react-scroll';
import { Link } from "react-router-dom"

//CSS Packages
import tw from "twin.macro";
import styled from "styled-components";
import "../../assets/css/home.css"

//Get logged account
import { getCurrentUser } from "../../services/account";

import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as ExitIcon } from "feather-icons/dist/icons/x.svg";

//CSS Classes
const Header = tw.header `
  flex justify-between items-center md:hidden
  w-full mx-auto fixed z-50 bg-gray-900 md:p-8 sm:p-0 h-20
`;

const NavLinks = tw.div `inline-flex mx-10`;
const NavLink = tw.span `
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 text-white cursor-pointer
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-blue-500 hocus:text-blue-400
`;


const LogoLink = styled(NavLink)
`
  ${tw`flex items-center font-black border-b-0 ml-10!`};

  img {
    ${tw`w-20 mr-3`}
  }
`;
const MobileNavLinks = tw.nav`
   justify-between items-center flex
`;



export default ({ links }) => {
    //Logged Account
    const [user, setUser] = useState("");
    useEffect(() => {
        async function getUser() {
            const _user = await getCurrentUser();
            setUser(_user);
        }
        getUser();
    }, []);

    //Logo without img
    const logoLink = (
        <LogoLink href="/">
            1GoodTravel.com
        </LogoLink>
    );

    const [colapse, setColapse] = useState(false);

    return (
        <>
            <Header>
                <MobileNavLinks>
                    {logoLink}
                    <NavLinks key={1} style={{ position: "absolute", right: "2%", color: "white", display: "inline-flex" }}>
                        {!user && <Link to="/login"><div style={{ margin: "10px", border: "1px solid white", padding: "5px", borderRadius: "50px" }}><UserIcon /></div></Link>}
                        {user && user.role == "Admin" && <Link to="admin"><div style={{ margin: "10px", border: "1px solid white", padding: "5px", borderRadius: "50px" }}><UserIcon /></div></Link>}

                        {!colapse && <div style={{ margin: "10px", border: "1px solid white", padding: "5px", cursor:"pointer" }}><MenuIcon onClick={() => setColapse(true)} /></div>}
                        {colapse && <div style={{ margin: "10px", border: "1px solid white", padding: "5px", cursor:"pointer" }}><ExitIcon onClick={() => setColapse(false)} /></div>}
                    </NavLinks>

                </MobileNavLinks>
            </Header>


            {colapse && <Header style={{ marginTop: "80px" }}>
                <MobileNavLinks>
                    <NavLinks key={1} style={{ position: "absolute", right: "10%", color: "white", display: "inline-flex" }}>
                        {links.map(l => (
                            <>

                                {l.function && <div onClick={() => l.function(l.to)} style={{ margin: "10px", cursor:"pointer" }}>{l.name}</div>}
                                {l.link && <Link to={l.to}><div style={{ margin: "10px" }}>{l.name}</div></Link>}

                                {!l.function && !l.link && <div style={{ margin: "10px", cursor:"pointer" }}>
                                    <LinkScroll
                                        activeClass="active"
                                        to={l.to}
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}>
                                        {l.name}
                                    </LinkScroll>
                                </div>}

                            </>
                        ))}
                    </NavLinks>

                </MobileNavLinks>
            </Header>}
        </>
    );
};