import React, { useState } from "react";
import { Link } from "react-router-dom";

//CSS Packages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import "../../assets/css/admin.css";

//Icons
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as ExitIcon } from "feather-icons/dist/icons/x.svg";

//CSS Classes
const Header = tw.header `
  flex justify-between items-center m-auto
`;
const MobileHeader = tw.header `
  flex justify-between items-center md:hidden
  w-full mx-auto fixed z-30 bg-gray-900 md:p-8 sm:p-0 h-20
`;

const NavLinks = tw.div `inline-block`;
const NavLink = tw.a `
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 text-blue-800
  font-semibold tracking-wide transition duration-300 cursor-pointer
  pb-1 border-b-2 border-transparent rounded p-2 hover:text-white hover:bg-blue-800
`;

const PrimaryLink = tw(NavLink)
`lg:mx-0 px-8 py-3 rounded bg-blue-500 text-gray-100
  hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

const LogoLink = styled(NavLink)
`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;
const DesktopNavLinks = tw.nav`
  md:flex lg:flex justify-between items-center m-auto
`;
const MobileNavLinks = tw.nav`
   justify-between items-center flex
`;


// function:
//		true => change rendering (only in manager page)
// link: 
//		true => reditect to another page
//		false => scroll in this page
//
// lo: 
//		direction to scroll or redirect. If function is true => Mode to render
// name:
//		link text
const mobileLinks = [
	{
		link: true,
		to: "/",
		name: "Home"
	},
	{
		function: true,
		link: false,
		to: "User",
		name: "Users"
	},
	{
		function: true,
		link: false,
		to: "Package",
		name: "Packages"
	},
];


export default ({ mode, setMode }) => {

	//Mobile logo without img
	const mobileLogoLink = (
		<LogoLink href="/">
			1GoodTravel.com
		</LogoLink>
	);
	
	//Mode change links (Users or Package)
	const links = [
		<NavLinks key={1}>
			<NavLink className={`admin-nav-link ${mode == "User" ? "active" : ""}`} onClick={() => setMode("User")} style={{ fontSize: "20px" }}>Users</NavLink>
			<NavLink className={`admin-nav-link ${mode == "Package" ? "active" : ""}`} onClick={() => setMode("Package")} style={{ fontSize: "20px" }}>Packages</NavLink>
		</NavLinks>
	];


	const [colapse, setColapse] = useState(false);

	return (
		<>
			{/*Desktop Links*/}
			<Header>
				<DesktopNavLinks>
					{links}
				</DesktopNavLinks>
			</Header>

			{/*Mobile Links*/}
			<MobileHeader>
				<MobileNavLinks>
					{mobileLogoLink}
					<MobileNavLinks key={1} style={{ position: "absolute", right: "2%", color: "white", display: "inline-flex" }}>
						<Link to="logot"><div style={{ margin: "10px", border: "1px solid white", padding: "5px", borderRadius: "50px" }}><UserIcon /></div></Link>

						{!colapse && <div style={{ margin: "10px", border: "1px solid white", padding: "5px", cursor: "pointer" }}><MenuIcon onClick={() => setColapse(true)} /></div>}
						{colapse && <div style={{ margin: "10px", border: "1px solid white", padding: "5px", cursor: "pointer" }}><ExitIcon onClick={() => setColapse(false)} /></div>}
					</MobileNavLinks>

				</MobileNavLinks>
			</MobileHeader>

			{/*Mobile Colapse Links*/}
			{colapse && <MobileHeader style={{ marginTop: "80px" }}>
				<MobileNavLinks>
					<MobileNavLinks key={1} style={{ position: "absolute", right: "10%", color: "white", display: "inline-flex" }}>
						{mobileLinks.map(l => (
							<>
								{l.function && <div onClick={() => l.function(l.to)} style={{ margin: "10px", cursor: "pointer" }}>{l.name}</div>}
								{l.link && <Link to={l.to}><div style={{ margin: "10px" }}>{l.name}</div></Link>}
							</>
						))}
					</MobileNavLinks>
				</MobileNavLinks>
			</MobileHeader>}
		</>
	);
};