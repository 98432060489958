import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom"
import axios from "axios";
import { API_URL } from "../../config.js";

//CSS Package
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

//Components
import AnimationRevealPage from "../Components/AnimationRevealPage.js";
import { Container as ContainerBase } from "../Components/Layouts";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";

import illustration from "assets/images/signup-illustration.svg";
import logo from "assets/images/Logo/last1.png";

//CSS Classes
const Container = tw(ContainerBase)
`min-h-screen bg-blue-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div `max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div `lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a ``;
const LogoImage = tw.img `h-20 mx-auto`;
const MainContent = tw.div `mt-12 flex flex-col items-center`;
const Heading = tw.h1 `text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div `w-full flex-1 mt-8`;

const Form = tw.form `mx-auto max-w-xs`;
const Input = tw.input `w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button `
  ${tw`mt-5 tracking-wide font-semibold bg-blue-500 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-blue-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default ({
	illustrationImageSrc = illustration,
	headingText = "Sign Up For 1Good Travel",
	submitButtonText = "Sign Up",
	SubmitButtonIcon = SignUpIcon,
	tosUrl = "/term",
	signInUrl = "/login",
	logoLinkUrl = "/"
}) => {

	//active when account logged
	const [register, setRegister] = useState(false);

	//Account info
	const [fullName, setFullName] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")
	const [password, setPassword] = useState("")
	const [rPassword, setRPassword] = useState("")

	//Register error
	const [error, setError] = useState("")

	//Register Account
	const handleRegister = (e) => {
		e.preventDefault()

		let _error = ""

		if (password != rPassword) _error = "Password and Repeat Password don't match";
		if (!rPassword) _error = "Please enter a valid repeat password";
		if (!password) _error = "Please enter a valid password";
		if (!phone) _error = "Please enter a valid phone";
		if (!email) _error = "Please enter a valid email";
		if (!fullName) _error = "Please enter a valid Full Name";

		setError(_error)
		if (_error) return

		const data = {
			fullName,
			email,
			phone,
			password
		};

		axios.post(`${API_URL}/account/register`, data)
			.then(res => {
				if (res.data.error)
					setError(res.data.error)
				else {
					setError('')
					setRegister(true)
				}
			})
			.catch(err => {
				console.log(err)
				setError("Please try again")
			})
	}

	return (
		<AnimationRevealPage>
			<Container>
				<Content>
					<MainContainer>
						<LogoLink href={logoLinkUrl}>
							<LogoImage src={logo} />
						</LogoLink>
						<MainContent>
							<Heading>{headingText}</Heading>
							<FormContainer>
								<Form onSubmit={handleRegister}>
									<Input value={fullName} onChange={(e) => setFullName(e.target.value)} type="text" placeholder="Full Name" />
									<Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />
									<Input value={phone} onChange={(e) => setPhone(e.target.value)} type="phone" placeholder="Phone" />
									<Input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
									<Input value={rPassword} onChange={(e) => setRPassword(e.target.value)} type="password" placeholder="Repeat Password" />

									<p style={{ color: 'red', textAlign: 'center', marginTop: "10px" }}>{error}</p>
									{register && <p style={{color:'blue', textAlign:"center"}}>You Account has been created successfully. An email has been sent to you with instructions to active your account</p>}

									<SubmitButton type="submit">
										<SubmitButtonIcon className="icon" />
										<span className="text">{submitButtonText}</span>
									</SubmitButton>
									<p tw="mt-6 text-xs text-gray-600 text-center">
										I agree to abide by 1goodtravel's{" "}
										<Link to={tosUrl} tw="border-b border-gray-500 border-dotted">
											Terms of Service
										</Link>{" "}
									</p>

									<p tw="mt-8 text-sm text-gray-600 text-center">
										Already have an account?{" "}
										<Link to={signInUrl} tw="border-b border-gray-500 border-dotted">
											Sign In
										</Link>
									</p>
								</Form>
							</FormContainer>
						</MainContent>
					</MainContainer>
					<IllustrationContainer>
						<IllustrationImage imageSrc={illustrationImageSrc} />
					</IllustrationContainer>
				</Content>
			</Container>
		</AnimationRevealPage>
	);
}