import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUser } from "../../services/account";

export default function PrivateRoute({ component: Component, ...rest }) {
    const [user, setUser] = useState("");

    useEffect(() => {

        async function getUser() {
            const _user = await getCurrentUser();
            setUser(_user);
        }
        getUser();
    }, []);

    if (!user) return null;

    return (
        <Route {...rest} render={props => (
            user ?
                <Component user={user} setUser={setUser} {...props} />
                : <Redirect to='/login' />
        )} />
    );
};