import React, { useEffect, useState } from "react";
import { Link as LinkScroll } from 'react-scroll';
import { Link } from "react-router-dom"

//Get logged account
import { getCurrentUser } from "../../services/account";

//CSS Package
import tw from "twin.macro";
import styled from "styled-components";
import "../../assets/css/home.css";

import logo from "../../assets/images/Logo/last1.png";

//Icons
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as ExitIcon } from "feather-icons/dist/icons/x.svg";


//CSS Classes
const Header = tw.header`
  flex justify-between items-center hidden md:flex
  w-full mx-auto fixed md:p-8 sm:p-0 md:h-20 z-50
  bg-gray-900
`;
const MobileHeader = tw.header`
  flex justify-between items-center md:hidden
  w-full mx-auto fixed z-50 bg-gray-900 md:p-8 sm:p-0 h-20
`;

const NavLinks = tw.div`inline-block z-50`;
const NavLink = tw.a`
  text-lg my-2 text-sm md:mx-2 lg:mx-6 my-0 text-white cursor-pointer
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-blue-500 hocus:text-blue-400
`;

const LogoLink = styled(NavLink)
    `
  ${tw`flex items-center font-black border-b-0 ml-10! md:ml-0!`};

  img {
    ${tw`w-20 mr-3`}
  }
`;

const DesktopNavLinks = tw.nav`
   justify-between items-center
`;

const Services = tw.p`
    text-xl m-2 lg:mx-6 text-white cursor-pointer
    font-semibold tracking-wide transition duration-300
    pb-1 border-b-2 border-transparent hover:border-blue-500 hocus:text-blue-400`;

const MobileNavLinks = tw.nav`
   justify-between items-center flex
`;

const MobileHeaderCollapse = tw.header`
  flex justify-center m-auto items-center md:hidden block rounded-lg
  w-full fixed z-50 bg-white text-blue-900 md:p-8 sm:p-0
`;

const Heading = styled.h1`
  ${tw`text-2xl font-black border-white text-black rounded-full leading-snug sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-blue-700 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;


export default ({ t, i18n }) => {

    const handleLanguageChange = () => {
        console.log(i18n.language == "es")
        if (i18n.language == "es") i18n.changeLanguage("en")
        else i18n.changeLanguage("es")
    }

    //Logged account
    const [user, setUser] = useState("");
    useEffect(() => {
        async function getUser() {
            const _user = await getCurrentUser();
            setUser(_user);
        }
        getUser();
    }, []);

    const [colapse, setColapse] = useState(false);
    const [services, setServices] = useState(false);
    const [account, setAccount] = useState(false);


    return (
        <>
            {/*Desktop Links*/}
            <Header id="header" style={{ height: (services || account) ? "210px" : "100px" }}>
                <DesktopNavLinks>

                    <LogoLink href="/">
                        <img src={logo} alt="logo" style={{ width: "120px", position: "fixed", top: "0" }} />
                    </LogoLink>

                    <NavLinks key={1} style={{ position: "fixed", right: "2%", top: "3%" }}>

                        <NavLink style={{ fontSize: "20px" }}>
                            <span onClick={handleLanguageChange}>{t("lenguage")}</span>
                        </NavLink>

                        <NavLink style={{ fontSize: "20px" }}>
                            <Link to="/hotels">{t("header.hotels")}</Link>
                        </NavLink>

                        <NavLink style={{ fontSize: "20px" }}>
                            <Link to="/cruise">{t("header.cruise")}</Link>
                        </NavLink>

                        <NavLink style={{ fontSize: "20px" }}>
                            <Link to="/flight_hotel">{t("header.flight_hotel")}</Link>
                        </NavLink>

                        <NavLink style={{ fontSize: "20px" }}>
                            <Link to="/#packages">{t("header.packages")}</Link>
                        </NavLink>

                        <span onMouseLeave={() => setServices(false)}>
                            <LinkScroll
                                activeClass="active"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                style={{ display: "inline-flex" }}
                                duration={500}>
                                <div>
                                    <Services onMouseEnter={() => setServices(true)}>{t("header.services")}</Services>
                                    {services && <div>
                                        <Services><Link to="/package">{t("header.package")}</Link></Services>
                                        <Services><Link to="/train_hotel">{t("header.train_hotel")}</Link></Services>
                                    </div>}
                                </div>
                            </LinkScroll>
                        </span>

                        <NavLink style={{ fontSize: "20px" }}>
                            <LinkScroll
                                activeClass="active"
                                to="footer"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}>
                                {t("header.contact")}
                            </LinkScroll>
                        </NavLink>

                        {/* <span tw="inline-flex" onMouseLeave={() => setAccount(false)}>
                            <LinkScroll
                                style={{ display: "inline-flex" }}>                                                                              
                                <div>
                                    <div onMouseEnter={() => setAccount(true)} style={{ textAlign:"center", marginBottom:"-10px" , padding: "5px", borderRadius: "50px", border:"2px solid white" }}><UserIcon /></div>
                                    {account && <div>
                                        {user && user.role == "Account" && <>
                                            <Services><Link to="/profile">Profile</Link></Services>
                                            <Services><Link to="/logout">Logout</Link></Services>
                                        </>}
                                        {user && user.role == "Admin" && <>
                                            <Services><Link to="/admin">Manager</Link></Services>
                                            <Services><Link to="/profile">Profile</Link></Services>
                                            <Services><Link to="/logout">Logout</Link></Services>
                                        </>}
                                        {!user && <>
                                            <Services><Link to="/login">Login</Link></Services>
                                            <Services><Link to="/register">Regsiter</Link></Services>
                                        </>}
                                    </div>}
                                </div>
                            </LinkScroll>
                        </span> */}

                        <span onMouseLeave={() => setAccount(false)}>
                            <LinkScroll
                                activeClass="active"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                style={{ display: "inline-flex" }}
                                duration={500}>
                                <div>
                                    <Services onMouseEnter={() => setAccount(true)}>{t("header.account")}</Services>
                                    {account && <div>
                                        {user && user.role == "Account" && <>
                                            <Services><Link to="/profile">{t("header.profile")}</Link></Services>
                                            <Services><Link to="/logout">{t("header.logout")}</Link></Services>
                                        </>}
                                        {user && user.role == "Admin" && <>
                                            <Services><Link to="/admin">{t("header.manager")}</Link></Services>
                                            <Services><Link to="/profile">{t("header.profile")}</Link></Services>
                                            <Services><Link to="/logout">{t("header.logout")}</Link></Services>
                                        </>}
                                        {!user && <>
                                            <Services><Link to="/login">{t("header.login")}</Link></Services>
                                            <Services><Link to="/register">{t("header.register")}</Link></Services>
                                        </>}
                                    </div>}
                                </div>
                            </LinkScroll>
                        </span>


                        {/* {user && <NavLink style={{ fontSize: "20px" }}>
                            <Link to="/logout">{t("header.logout")}</Link>
                        </NavLink>}
                        {!user && <NavLink style={{ fontSize: "20px" }}>
                            <Link to="/login">{t("header.login")}</Link>
                        </NavLink>} */}

                    </NavLinks>

                </DesktopNavLinks>
            </Header>

            {/*Mobile Links*/}
            <MobileHeader>
                <MobileNavLinks>

                    <LogoLink href="/">
                        OneGoodTravel
                    </LogoLink>

                    <MobileNavLinks key={1} style={{ position: "absolute", right: "2%", color: "white", display: "inline-flex" }}>
                        <NavLink style={{ fontSize: "18px", margin:"20px" }}>
                            <span onClick={handleLanguageChange}>{t("lenguage")}</span>
                        </NavLink>
                        <div style={{ margin: "10px", border: "1px solid white", borderRadius: "50px", padding: "5px", cursor: "pointer" }}><UserIcon onClick={() => setColapse(!colapse)} /></div>
                    </MobileNavLinks>

                </MobileNavLinks>
            </MobileHeader>

            {/*Mobile Colapse Links*/}
            <MobileHeader style={{ marginTop: "80px" }}>
                <MobileNavLinks>
                    <MobileNavLinks key={1} style={{ position: "absolute", right: "2%", color: "white", display: "inline-flex" }}>

                        <span>
                            <Services style={{ color: "white", fontSize: "14px" }} onClick={() => setServices(!services)}>{t("header.services")}</Services>
                        </span>

                        <NavLink style={{ fontSize: "20px" }}>
                            <Link to="/#packages">{t("header.packages")}</Link>
                        </NavLink>

                        <NavLink style={{ fontSize: "14px", margin: "7px" }}>
                            <Link to="/hotels">{t("header.hotels")}</Link>
                        </NavLink>

                        <NavLink style={{ fontSize: "14px", margin: "7px" }}>
                            <Link to="/flight_hotel">{t("header.flight_hotel")}</Link>
                        </NavLink>

                        <NavLink style={{ fontSize: "14px", margin: "7px" }}>
                            <LinkScroll
                                activeClass="active"
                                to="footer"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}>
                                {t("header.contact")}
                            </LinkScroll>
                        </NavLink>
                    </MobileNavLinks>
                </MobileNavLinks>
            </MobileHeader>

            {services && <MobileHeader style={{ marginTop: "150px" }}>
                <MobileNavLinks>
                    <MobileNavLinks key={1} style={{ position: "absolute", right: "2%", color: "white", display: "inline-flex" }}>
                        <NavLink style={{ fontSize: "14px", margin: "7px" }}>
                            <Link to="/package">{t("header.package")}</Link>
                        </NavLink>
                        <NavLink style={{ fontSize: "14px", margin: "7px" }}>
                            <Link to="/train_hotel">{t("header.train_hotel")}</Link>
                        </NavLink>
                        <NavLink style={{ fontSize: "14px", margin: "7px" }}>
                            <Link to="/cruise">{t("header.cruise")}</Link>
                        </NavLink>
                    </MobileNavLinks>
                </MobileNavLinks>
            </MobileHeader>}

            {colapse && <MobileHeaderCollapse style={{ marginTop: "80px", padding: "20px", boxShadow: "6px 20px 100px gray" }}>
                <div style={{ padding: "20px" }}>
                    {!user && <div>
                        <Heading>{t("header.colapse-title")}</Heading>
                        <p>{t("header.colapse-subtitle")}</p>
                        <Link to="/login"> <SubmitButton>{t("header.sign-in")}</SubmitButton></Link>
                        <Link to="/register"><p style={{ textAlign: "center", marginTop: "5px", color: "#4299e1" }}>{t("header.sign-up")}</p></Link>
                    </div>}

                    {user && user.role == "Account" && <div style={{ textAlign: "center" }}>
                        <Heading>{t("header.hi")}, {user.fullName}</Heading>
                        <p>{user.email}</p>
                        <br />
                        <hr />
                        <div style={{ margin: "20px" }}>
                            <Link to="/profile"><p style={{ textAlign: "start", marginTop: "5px", color: "black" }}>{t("header.account")}</p></Link>
                            <Link to="/#footer"><p style={{ textAlign: "start", marginTop: "5px", color: "black" }}>{t("header.support")}</p></Link>
                            <Link to="/logout"><p style={{ textAlign: "start", marginTop: "5px", color: "black" }}>{t("header.logout")}</p></Link>
                        </div>
                        <hr />
                    </div>}

                    {user && user.role == "Admin" && <div style={{ textAlign: "center" }}>
                        <Heading>{t("header.hi")}, {user.fullName}</Heading>
                        <p>{user.email}</p>
                        <br />
                        <hr />
                        <div style={{ margin: "20px" }}>
                            <Link to="/admin"><p style={{ textAlign: "start", marginTop: "5px", color: "black" }}>{t("header.manager")}</p></Link>
                            <Link to="/logout"><p style={{ textAlign: "start", marginTop: "5px", color: "black" }}>{t("header.logout")}</p></Link>
                        </div>
                        <hr />
                    </div>}
                </div>


            </MobileHeaderCollapse>}
        </>
    );
};