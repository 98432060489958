import axios from 'axios';
import { API_URL } from "../config.js";

export async function getCurrentUser() {
    try {
        const res = await axios.get(`${API_URL}/account/me`, {
            headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
        });
        if (res.data.error)
            return null;
        return res.data.account;

    } catch (error) {
        return null;
    }
}

export async function getAdmin() {
    try {
        const res = await axios.get(`${API_URL}/admin/me`, {
            headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
        });
        if (res.data.error)
            return null;
        return res.data.account;

    } catch (error) {
        return null;
    }
}

export function logout() {
    localStorage.removeItem("1good_travel_account_token");
}