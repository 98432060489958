import React, { forwardRef, useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config.js";

//CSS Packages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line


//Date type input
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { PrimaryButton as PrimaryButtonBase } from "../Components/Buttons.js";
import { SectionHeading } from "../Components/Headings.js";

//Iconst
import { ReactComponent as CalendarIcon } from "feather-icons/dist/icons/calendar.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";


import OrlandoIMG from "../../assets/images/Tourist Packages/Orlando/6.jpg";

//CSS Classes
const Heading = tw(SectionHeading)
`text-left text-gray-900`;

const Container = tw.div `relative`;
const Content = tw.div `max-w-screen-xl m-auto py-20 lg:py-24`;

const TabContent = tw.div `mt-6 flex flex-wrap m-auto`;
const CardContainer = tw.div `mt-10 w-full sm:w-1/2 md:w-1/2 lg:w-1/3 p-5 m-auto`;
const Card = tw.div `lg:mx-4 md:m-0 mt-10! flex flex-col h-full w-full`;
const CardMeta = styled.div `
  ${tw`flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;
const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
	${tw`w-5 h-5 mr-1`}
  }
`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;

const Image = styled.div(props => [
	`background-image: url("${props.imageSrc}");`,
	tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-blue-500 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
  ${tw`inline-block mt-4 text-sm font-semibold`}`
const MainContent = tw.div`flex flex-col items-center p-0`;
const FHeading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const Form = tw.form``;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full lg:w-11/12 mx-auto px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`tracking-wide font-semibold bg-blue-500 text-gray-100 w-full lg:w-11/12 mx-auto py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl m-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-center lg:ml-6 md:lg:ml-6`;
const RightColumn = tw.div`relative md:w-full lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-center mt-5 lg:mt-0`;

const CustomInput = forwardRef(({ value, onClick }, ref) => (
	<button
		tw="w-full px-8 text-left py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
		onClick={(e) => {
			e.preventDefault()
			onClick(e)
		}} ref={ref}>{value}</button>
))

export default ({ }) => {

	//Listssof Packages, Locations and Included
	const [packages, setPackages] = useState([]);
	const [packageTypes, setPackageTypes] = useState([])
	const [packageIncludes, setPackageIncludes] = useState([])
	useEffect(() => {
		getPackages();
		getPackageTypes();
		getPackageIncludes();
	}, []);

	async function getPackages() {
		const result = await axios.get(`${API_URL}/package/getAll/`);
		setPackages(result.data.packages)
	}
	async function getPackageTypes() {
		const result = await axios.get(`${API_URL}/package/getAllTypes`, {
			headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
		});
		setPackageTypes(result.data.packageTypes)
	}
	async function getPackageIncludes() {
		const result = await axios.get(`${API_URL}/package/getAllIncludes`, {
			headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
		});
		setPackageIncludes(result.data.packageIncludes)
	}

	const getDate = date => {
		const UTC = new Date(date);
		const normal = UTC.toISOString();
		return new Date(normal.substring(0, normal.length - 1));
	}
	//Given a package returns the range of avaliable dates
	const getFormatDate = p => {

		const startUTC = new Date(p.startDate);
		const startNormal = startUTC.toISOString();
		const start = new Date(startNormal.substring(0, startNormal.length - 1));

		const endUTC = new Date(p.endDate);
		const endNormal = endUTC.toISOString();
		const end = new Date(endNormal.substring(0, endNormal.length - 1));



		const startM = start.toLocaleString("en-US", { month: "short" })
		const endM = start.toLocaleString("en-US", { month: "short" })


		const startR = startM + " " + start.getDate() + ", " + start.getFullYear();
		const endR = endM + " " + end.getDate() + ", " + end.getFullYear();

		return startR + " - " + endR
	}

	//Cnew => active when creating a package
	const [Cnew, setCNew] = useState(false);

	//List of Included
	const [include, setInclude] = useState("Select Package Include");
	const [newInclude, setNewInclude] = useState("")
	const [included, setIncluded] = useState([]);
	//Included Methods
	//##################################################################
	//Add to list
	const handleAdd = () => {
		if (include == "Select Package Include") return;
		if (include == "_ADDNEW_") return;
		if (included.indexOf(include) >= 0) return;

		const _included = [...included]
		_included.push(include)
		setIncluded(_included)
	}
	//Remove to list
	const handleRest = (_include) => {
		const _included = [...(included.filter(i => i != _include))]
		setIncluded(_included)
	}
	//Create new
	const handleAddInclude = (e) => {
		e.preventDefault()
		if (newInclude == "") return;
		axios.post(`${API_URL}/package/createInclude`, { name: newInclude }, {
			headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
		}).then(res => {
			getPackageIncludes()
			setInclude("Select Package Include")
		}).catch(err => console.log(err))
	}
	//Delete
	const handleDeleteInclude = (e) => {
		e.preventDefault()
		if (include == "") return;
		axios.post(`${API_URL}/package/deleteInclude`, { name: include }, {
			headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
		}).then(res => {
			getPackageIncludes()
			setInclude("Select Package Include")
		}).catch(err => console.log(err))
	}
	//###################################################################


	//Location of Package
	const [type, setType] = useState("Select Package Type");
	const [newType, setNewType] = useState("")
	//Locations Methods
	//###################################################################
	//Create new
	const handleAddType = (e) => {
		e.preventDefault()
		if (newType == "") return;
		axios.post(`${API_URL}/package/createType`, { name: newType }, {
			headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
		}).then(res => {
			setType("Select Package Type")
			getPackageTypes()
		}).catch(err => console.log(err))
	}
	//Delete
	const handleDeleteType = (e) => {
		e.preventDefault()
		if (type == "") return;
		axios.post(`${API_URL}/package/deleteType`, { name: type }, {
			headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
		}).then(res => {
			getPackageTypes()
			setType("Select Package Type")
		}).catch(err => console.log(err))
	}
	//###################################################################

	//Active when deleting a package
	const [remove, setRemove] = useState(-1);
	//Active when editing a package
	const [edit, setEdit] = useState(-1);

	//Creating Package
	//###################################################################
	const [name, setName] = useState("");
	const [price, setPrice] = useState("");
	const [description, setDescription] = useState("");
	const [numberDays, setNDays] = useState("");
	const [startDate, setStartD] = useState(new Date());
	const [endDate, setEndD] = useState(new Date());

	//Create new
	const handleCreate = (e) => {
		e.preventDefault();

		const data = {
			name,
			type,
			price,
			description,
			numberDays,
			startDate,
			endDate,
			includes: included,
			dataImages
		};

		axios.post(`${API_URL}/package/create`, data, {
			headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
		}).then(res => {
			getPackages()
			setCNew(false)
		}).catch(err => console.log(err))
	}
	//Delete
	const handleRemove = () => {
		const _package = packages[remove];

		axios.post(`${API_URL}/package/remove`, { id: _package._id }, {
			headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
		}).then(res => {
			getPackages()
			setRemove(-1);
		}).catch(err => console.log(err))
	}

	//Edit
	const handleSetEdit = (i) => {
		const _edit = i
		const _package = packages[_edit]
		
		setName(_package.name)
		setPrice(_package.price)
		setDescription(_package.description)
		setNDays(_package.numberDays)
		setStartD(new Date(_package.startDate))
		setEndD(new Date(_package.endDate))
		
		setEdit(_edit)
	}
	const handleEdit = (e) => {
		e.preventDefault();
		const _package = packages[edit]

		const data = {
			packageId: _package._id,
			name,
			type,
			price,
			description,
			numberDays,
			startDate,
			endDate,
			includes: included,
		};

		axios.post(`${API_URL}/package/update`, data, {
			headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
		}).then(res => {
			getPackages()
			setEdit(-1)
		}).catch(err => console.log(err))

	}
	//####################################################################

	//Image Methods
	//####################################################################
	const [dataImages, setDataImages] = useState([])
	const searchImage = (_image) => {
		for (let i = 0; i < dataImages.length; i++) {
			const element = dataImages[i];
			if (_image.imageName == element.imageName) return i;
		}
		return -1;
	}
	const addImage = (data) => {
		const index = searchImage(data)
		if (index != -1) return;

		const _images = [...dataImages]
		_images.push(data)
		setDataImages(_images)
	}
	const removeImage = (i) => {
		const index = searchImage(i)
		if (index == -1) return;

		const _images = [...dataImages]
		_images.splice(index, 1)
		setDataImages(_images)
	}
	const onSelectFile = async (event) => {
		const file = event.target.files[0];
		const convertedFile = await convertToBase64(file);

		const data = {
			image: convertedFile,
			imageName: file.name
		}

		addImage(data)
	}
	const convertToBase64 = (file) => {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				resolve(reader.result);
			}
		})
	}
	//#####################################################################

	return (
		<Container>
			<Content>
				{/* Edit Package*/}
				{edit > -1 && !Cnew && <MainContent style={{ marginTop: "-80px" }}>
					<FHeading>Edit Package</FHeading>
					<Form style={{ marginTop: "-50px" }} onSubmit={handleEdit}>

						<div style={{ marginTop: "100px", marginBottom: "-70px" }}>
							{/* Location */}
							<Select onChange={(e) => setType(e.target.value)} style={{ width: "90%" }}>
								<option value="Select Package Type">Select Package Type</option>
								{packageTypes.map(pType => (
									<option value={pType.name}>{pType.name}</option>
								))}
								<option value="_ADDNEW_">Add New</option>
							</Select>

							{type == "_ADDNEW_" && <Input value={newType} onChange={(e) => setNewType(e.target.value)} type="text" placeholder="New Type" style={{ width: "90%" }} />}
							{type == "_ADDNEW_" && <SubmitButton onClick={handleAddType} style={{ marginTop: "10px" }}>
								<PlusIcon />
								<span className="text">Add New Type</span>
							</SubmitButton>}
							{type != "_ADDNEW_" && type != "Select Package Type" && <SubmitButton onClick={handleDeleteType} style={{ marginTop: "10px" }}>
								<MinusIcon />
								<span className="text">Delete Type</span>
							</SubmitButton>}
						</div>

						{/* Form */}
						<TwoColumn>
							<LeftColumn>
								<Input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder={packages[edit].name} />
								<Input value={price} onChange={(e) => setPrice(e.target.value)} type="phone" placeholder={packages[edit].price} />
								<TextArea value={description} onChange={(e) => setDescription(e.target.value)} type="text" placeholder={packages[edit].description} style={{ maxHeight: "130px", minHeight: "130px" }} />
							</LeftColumn>
							<RightColumn>

								<p style={{ marginTop: "30px" }}>Start - End Date</p>
								<div tw="w-full flex z-40">
									<DatePicker
										selected={startDate}
										onChange={(d) => setStartD(d)}
										customInput={<CustomInput />}
									/>
								</div>
								<div tw="w-full flex z-40">
									<DatePicker
										selected={endDate}
										onChange={(d) => setEndD(d)}
										customInput={<CustomInput />}
									/>
								</div>
								<Input value={numberDays} onChange={(e) => setNDays(e.target.value)} type="text" placeholder={packages[edit].numberDays} />
							</RightColumn>
						</TwoColumn>

						{/* Included */}
						<div style={{ textAlign: "center", marginTop: "-50px" }}>
							<hr />
							<br />
							<FHeading>Included</FHeading>

							<div style={{ marginTop: "10px" }}>
								{included.map((v, i) => (
									<div style={{ display: "inline-flex", textAlign: "center", width: "100%" }}>
										<div style={{ width: "90%" }}>
											<p>{v}</p>
										</div>
										<div style={{ width: "10%", marginLeft: "20px" }}>
											<MinusIcon tw="bg-blue-900 rounded-full w-6 h-6 text-white" onClick={() => handleRest(v)} style={{ cursor: "pointer" }}></MinusIcon>
										</div>
										<div style={{ marginBottom: "50px" }}></div>
									</div>
								))}
							</div>

							<div style={{ display: "inline-flex", width: '100%' }}>
								<div style={{ width: '90%', textAlign: "start", marginLeft: "50px" }}>
									<Select onChange={(e) => setInclude(e.target.value)} style={{ width: "100%" }}>
										<option value="Select Package Include">Select Package Include</option>
										{packageIncludes.map(pInclude => (
											<option value={pInclude.name}>{pInclude.name}</option>
										))}
										<option value="_ADDNEW_">Add New</option>
									</Select>
								</div>
								<div style={{ width: '10%', marginLeft: "20px" }}>
									<PlusIcon onClick={handleAdd} tw="bg-blue-900 rounded-full w-6 h-6 text-white" style={{ cursor: "pointer", marginTop: "10px" }}></PlusIcon>
								</div>
							</div>

							{include == "_ADDNEW_" && <Input value={newInclude} onChange={(e) => setNewInclude(e.target.value)} type="text" placeholder="New Include" style={{ width: "90%" }} />}
							{include == "_ADDNEW_" && <SubmitButton onClick={handleAddInclude} style={{ marginTop: "10px" }}>
								<PlusIcon />
								<span className="text">Add New Include</span>
							</SubmitButton>}
							{include != "_ADDNEW_" && include != "Select Package Include" && <SubmitButton onClick={handleDeleteInclude} style={{ marginTop: "10px" }}>
								<MinusIcon />
								<span className="text">Delete Include</span>
							</SubmitButton>}

						</div>

						{/* Button */}
						<SubmitButton type="submit" style={{ marginTop: "50px", marginBottom: "-50px" }}>
							<PlusIcon />
							<span className="text">Edit</span>
						</SubmitButton>

					</Form>
				</MainContent>}
				{/* Create new Package Button */}
				{edit == -1 && !Cnew && <div onClick={() => setCNew(true)} tw="items-center p-5 border-2 border-dashed border-blue-400 rounded-lg inline-block" style={{ marginBottom: "100px", marginTop: "-100px", cursor: "pointer" }}>
					<PlusIcon tw="text-white bg-gray-900 rounded-full w-6 h-6" style={{ margin: "auto" }}></PlusIcon>
					<Heading>Create New</Heading>
				</div>}

				{/* Create new Package Form */}
				{edit == -1 && Cnew && <MainContent style={{ marginTop: "-80px" }}>
					<FHeading>Create new Package</FHeading>
					<Form style={{ marginTop: "-50px" }} onSubmit={handleCreate}>

						<div style={{ marginTop: "100px", marginBottom: "-70px" }}>
							{/* Location */}
							<Select onChange={(e) => setType(e.target.value)} style={{ width: "90%" }}>
								<option value="Select Package Type">Select Package Type</option>
								{packageTypes.map(pType => (
									<option value={pType.name}>{pType.name}</option>
								))}
								<option value="_ADDNEW_">Add New</option>
							</Select>

							{type == "_ADDNEW_" && <Input value={newType} onChange={(e) => setNewType(e.target.value)} type="text" placeholder="New Type" style={{ width: "90%" }} />}
							{type == "_ADDNEW_" && <SubmitButton onClick={handleAddType} style={{ marginTop: "10px" }}>
								<PlusIcon />
								<span className="text">Add New Type</span>
							</SubmitButton>}
							{type != "_ADDNEW_" && type != "Select Package Type" && <SubmitButton onClick={handleDeleteType} style={{ marginTop: "10px" }}>
								<MinusIcon />
								<span className="text">Delete Type</span>
							</SubmitButton>}

							{/* Images */}
							{dataImages && dataImages.map(i => (
								<div style={{ display: "inline-flex", textAlign: "center", width: "100%", marginTop: "10px" }}>
									<div style={{ width: "50%" }}>
										<p>{i.imageName}</p>
									</div>
									<div style={{ width: "50%", marginLeft: "20px" }}>
										<MinusIcon tw="bg-blue-900 rounded-full w-6 h-6 text-white" onClick={() => removeImage(i)} style={{ cursor: "pointer" }}></MinusIcon>
									</div>
									<div style={{ marginBottom: "50px" }}></div>
								</div>
							))}
							<Input type="file" accept="image/*" onChange={onSelectFile} style={{ width: "90%" }} />
						</div>

						{/* Form */}
						<TwoColumn>
							<LeftColumn>
								<Input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Name" />
								<Input value={price} onChange={(e) => setPrice(e.target.value)} type="phone" placeholder="Price" />
								<TextArea value={description} onChange={(e) => setDescription(e.target.value)} type="text" placeholder="Description" style={{ maxHeight: "130px", minHeight: "130px" }} />
							</LeftColumn>
							<RightColumn>

								<p style={{ marginTop: "30px" }}>Start - End Date</p>
								<div tw="w-full flex z-40">
									<DatePicker
										selected={startDate}
										onChange={(d) => setStartD(d)}
										customInput={<CustomInput />}
									/>
								</div>
								<div tw="w-full flex z-40">
									<DatePicker
										selected={endDate}
										onChange={(d) => setEndD(d)}
										customInput={<CustomInput />}
									/>
								</div>
								<Input value={numberDays} onChange={(e) => setNDays(e.target.value)} type="text" placeholder="Number of Nigth" />
							</RightColumn>
						</TwoColumn>

						{/* Included */}
						<div style={{ textAlign: "center", marginTop: "-50px" }}>
							<hr />
							<br />
							<FHeading>Included</FHeading>

							<div style={{ marginTop: "10px" }}>
								{included.map((v, i) => (
									<div style={{ display: "inline-flex", textAlign: "center", width: "100%" }}>
										<div style={{ width: "90%" }}>
											<p>{v}</p>
										</div>
										<div style={{ width: "10%", marginLeft: "20px" }}>
											<MinusIcon tw="bg-blue-900 rounded-full w-6 h-6 text-white" onClick={() => handleRest(v)} style={{ cursor: "pointer" }}></MinusIcon>
										</div>
										<div style={{ marginBottom: "50px" }}></div>
									</div>
								))}
							</div>

							<div style={{ display: "inline-flex", width: '100%' }}>
								<div style={{ width: '90%', textAlign: "start", marginLeft: "50px" }}>
									<Select onChange={(e) => setInclude(e.target.value)} style={{ width: "100%" }}>
										<option value="Select Package Include">Select Package Include</option>
										{packageIncludes.map(pInclude => (
											<option value={pInclude.name}>{pInclude.name}</option>
										))}
										<option value="_ADDNEW_">Add New</option>
									</Select>
								</div>
								<div style={{ width: '10%', marginLeft: "20px" }}>
									<PlusIcon onClick={handleAdd} tw="bg-blue-900 rounded-full w-6 h-6 text-white" style={{ cursor: "pointer", marginTop: "10px" }}></PlusIcon>
								</div>
							</div>

							{include == "_ADDNEW_" && <Input value={newInclude} onChange={(e) => setNewInclude(e.target.value)} type="text" placeholder="New Include" style={{ width: "90%" }} />}
							{include == "_ADDNEW_" && <SubmitButton onClick={handleAddInclude} style={{ marginTop: "10px" }}>
								<PlusIcon />
								<span className="text">Add New Include</span>
							</SubmitButton>}
							{include != "_ADDNEW_" && include != "Select Package Include" && <SubmitButton onClick={handleDeleteInclude} style={{ marginTop: "10px" }}>
								<MinusIcon />
								<span className="text">Delete Include</span>
							</SubmitButton>}

						</div>

						{/* Button */}
						<SubmitButton type="submit" style={{ marginTop: "50px", marginBottom: "-50px" }}>
							<PlusIcon />
							<span className="text">Create</span>
						</SubmitButton>

					</Form>
				</MainContent>}

				{/* Packages List */}
				{edit == -1 && !Cnew && <TabContent>
					{packages.map((p, index) => (
						<CardContainer>
							<Card>
								<Image imageSrc={p.image ? `https://1goodtravel.s3.amazonaws.com/${p.image}` : OrlandoIMG} />

								<Details style={{ textAlign: "center" }}>

									<CardMeta>
										<CardMetaFeature>
											<TimeIcon /> {p.numberDays} Nigth
										</CardMetaFeature>
										<CardMetaFeature>
											<CalendarIcon /> {getFormatDate(p)}
										</CardMetaFeature>
									</CardMeta>

									<CardHeader>
										<Title>{p.name}</Title>
										<CardPrice>
											<CardPriceAmount>${p.price}</CardPriceAmount>
										</CardPrice>
									</CardHeader>
									<Description style={{ textAlign: "start" }}>{p.description.substring(0,47)}...</Description>
									<Link onClick={() => handleSetEdit(index)} style={{ cursor: "pointer" }}>Edit Package</Link>
									<Link onClick={() => setRemove(index)} style={{ cursor: "pointer" }}>Delete Package</Link>
									{remove == index && <div style={{ marginTop: "10px" }}>
										<p>Are Your Shure</p>
										<Link onClick={handleRemove} style={{ cursor: "pointer" }}>Yes</Link>
										<Link onClick={() => setRemove(-1)} style={{ marginLeft: "5px", cursor: "pointer" }}>No</Link>
									</div>}
								</Details>
							</Card>
						</CardContainer>
					))}
				</TabContent>}
			</Content>
		</Container>
	);
};