import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config.js";
import { Redirect } from "react-router-dom"
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from "react-i18next"

//Components
import AnimationRevealPage from "../Components/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../Components/Layouts";

import Header from "./Header";
import Personal from "./Personal.js"
import Footer from "../Components/Footer.js";

export default ({user, setUser}) => {
	const [t, i18n] = useTranslation("global")

    const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init('335539945398054', options);
    ReactPixel.pageView();

	return (
		<AnimationRevealPage>
			<Container>
				<Header t={t} i18n={i18n} />
				<ContentWithPaddingXl>
                    <Personal user={user} setUser={setUser} t={t}/>
				</ContentWithPaddingXl>
			</Container>
			<Footer t={t}/>
		</AnimationRevealPage >
	);
};