import React, { forwardRef, useEffect, useState } from "react";

//CSS Packages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

//Date type input
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//CSS CLasses
const Heading = styled.h1 `
  ${tw`md:text-5xl text-xl font-black text-white border-white rounded-full leading-snug md:ml-0 -ml-10 mt-20 md:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SubHeading = tw.p`text-white md:p-1 my-5 md:ml-0 -ml-10 w-full text-lg md:text-xl`

const Input = tw.input`w-full cursor-pointer px-8 py-4 font-medium border bg-white text-black placeholder-gray-900 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`;
const SubmitButton = tw.button`w-full px-8 py-4 font-medium border bg-blue-700 hover:bg-blue-900 text-white cursor-pointer`;

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
        style={{ height: "60px" }}
        tw="w-full text-left cursor-pointer px-8 py-4 font-medium border bg-white text-black placeholder-gray-900 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
        onClick={(e) => {
            e.preventDefault()
            onClick(e)
        }} ref={ref}>{value}</button>
))

export default ({ t, i18n, setSearch, location, setLocation, start, setStart, end, setEnd }) => {

    return (
        <div style={{paddingTop:"70px"}}>
            <div tw="md:px-20 md:py-0 px-20 py-10" style={{ textAlign: "start", backgroundColor: "#1a202c", paddingBottom: "100px" }}>
                <Heading style={{margin:"30px"}}>
                    {t("searchs.title1")} <br /> {t("searchs.title2")}
                </Heading>
                <SubHeading>{t("searchs.subtitle")}</SubHeading>
            </div>

            <div>
                <div style={{ backgroundColor: "#ecc94b", padding: "5px", boxShadow: "3px 10px 5px #aaaaaa", width: "80%", margin: "auto", marginTop: "-30px" }}>
                    <div tw="w-full flex flex-col md:flex-row">
                        <div tw="w-full md:w-2/5 flex">
                            <Input value={location} onChange={(e) => setLocation(e.target.value)} type="text" placeholder={t("searchs.input")} />
                        </div>
                        <div tw="w-full md:w-1/5 flex" >
                            <DatePicker
                                selected={start}
                                onChange={(d) => setStart(d)}
                                customInput={<CustomInput />}
                            />
                        </div>
                        <div tw="w-full md:w-1/5 flex" >
                            <DatePicker
                                selected={end}
                                onChange={(d) => setEnd(d)}
                                customInput={<CustomInput />}
                            />
                        </div>

                        <div tw="w-full md:w-1/5 flex" >
                            <SubmitButton onClick={setSearch}>{t("searchs.button")}</SubmitButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};