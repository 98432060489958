import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom"
import axios from "axios";
import { API_URL } from "../../config.js";

//CSS Pckages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

//Components
import AnimationRevealPage from "../Components/AnimationRevealPage.js";
import { Container as ContainerBase } from "../Components/Layouts";
import illustration from "assets/images/login-illustration.svg";


import logo from "assets/images/Logo/last1.png";
import { ReactComponent as KeyIcon } from "feather-icons/dist/icons/key.svg";

//CSS Classes
const Container = tw(ContainerBase)
`min-h-screen bg-blue-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div `max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div `lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a ``;
const LogoImage = tw.img `h-20 mx-auto`;
const MainContent = tw.div `mt-12 flex flex-col items-center`;
const Heading = tw.h1 `text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div `w-full flex-1 mt-8`;

const Form = tw.form `mx-auto max-w-xs`;
const Input = tw.input `w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button `
  ${tw`mt-5 tracking-wide font-semibold bg-blue-500 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-blue-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;


export default ({
	illustrationImageSrc = illustration,
	submitButtonText = "Recover",
	headingText = "Recover	 1Good Travel Password",
	SubmitButtonIcon = KeyIcon,
	loginUrl = "/login",
	signupUrl = "/register",
	logoLinkUrl = "/",

}) => {

	const [reset, setReset] = useState(false)
	//account info
	const [email, setEmail] = useState("")

	//login error
	const [error, setError] = useState("")

	//Login account
	const handleReset = (e) => {
		e.preventDefault()

		let _error = ""

		if (!email) _error = "Please enter a valid email";

		setError(_error)
		if (_error) return

		const data = {email};

		axios.post(`${API_URL}/account/reset`, data)
			.then(res => {
				if (res.data.error)
					setError(res.data.error)
				else {
					setError('')
					setReset(true)
				}
			})
			.catch(err => {
				console.log(err)
				setError("Please try again")
			})
	}

	return (
		<AnimationRevealPage>
			<Container>
				<Content>
					<MainContainer>
						<LogoLink href={logoLinkUrl}>
							<LogoImage src={logo} />
						</LogoLink>
						<MainContent tw="text-center">
							<Heading>{headingText}</Heading>
							<FormContainer>
								<Form onSubmit={handleReset}>
									<Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />
									<p style={{color:'red'}}>{error}</p>
									{reset && <p style={{color:'blue'}}>An email has been sent to you with instructions to recover your password</p>}
									<SubmitButton type="submit">
										<SubmitButtonIcon className="icon" />
										<span className="text">{submitButtonText}</span>
									</SubmitButton>
								</Form>
								<p tw="mt-6 text-xs text-gray-600 text-center">
									<a href={loginUrl} tw="border-b border-gray-500 border-dotted">
										Remenbered Password ?
									</a>
								</p>
								<p tw="mt-8 text-sm text-gray-600 text-center">
									Dont have an account?{" "}
									<a href={signupUrl} tw="border-b border-gray-500 border-dotted">
										Sign Up
									</a>
								</p>
							</FormContainer>
						</MainContent>
					</MainContainer>
					<IllustrationContainer>
						<IllustrationImage imageSrc={illustrationImageSrc} />
					</IllustrationContainer>
				</Content>
			</Container>
		</AnimationRevealPage>
	);
}