import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config.js";
import { Redirect } from "react-router-dom"
import { useTranslation } from "react-i18next"

//Components
import AnimationRevealPage from "../Components/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../Components/Layouts";

import Header from "./Header";
import Details from "./Details.js"
import Footer from "../Components/Footer.js";
import ReactPixel from 'react-facebook-pixel';

export default (props) => {
	const [t, i18n] = useTranslation("global")

	const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init('335539945398054', options);
    ReactPixel.pageView();

	//Error in URL
	const [error, setError] = useState(false)

	//Package to show
	const [element, setElement] = useState()

	useEffect(async () => {

		//Get package id
		const search = props.history.location.search
		if (search) {
			try {
				const line = search.split("?");
				const params = line[1].split("=")
				const _value = params[1]

				const result = await axios.post(`${API_URL}/package/get`, { id: _value });
				if (!result.data.package)
					setError(true)
				setElement(result.data.package)
			}
			catch (err) {
				setError(true)
			}
		}
		else
			setError(true)
	}, []);

	if (error) return <Redirect to="/" />

	return (
		<AnimationRevealPage>
			<Container>
				<Header t={t} i18n={i18n} />
				<ContentWithPaddingXl >
					<Details element={element} t={t} i18n={i18n}/>
				</ContentWithPaddingXl>
			</Container>
			<Footer t={t} ReactPixel={ReactPixel}/>
		</AnimationRevealPage >
	);
};