import React, { useState } from "react";
import { Link } from "react-router-dom"

import { useTranslation } from "react-i18next"


//CSS Pakcages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import "../../assets/css/home.css";

//Carousel (hero imgs)
import HeroCarousel from "react-hero-carousel";

//Icons
import TiktokIconB from "../../assets/images/Hero/tiktok_blanco.png";
import TiktokIconN from "../../assets/images/Hero/tiktok_negro.png";
import { ReactComponent as TwitterIcon } from "feather-icons/dist/icons/twitter.svg";
import { ReactComponent as InstagramIcon } from "feather-icons/dist/icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "feather-icons/dist/icons/youtube.svg";
import { ReactComponent as FacebookIcon } from "feather-icons/dist/icons/facebook.svg";

//CSS Classes
const Container = tw.div `relative hidden md:block`;
const Column = tw.div `xl:pl-10 py-8 relative bg-center bg-cover h-screen`;
const HeroContainer = tw.div `relative px-6 sm:px-8 mx-auto w-full h-full`;
const Content = tw.div `px-4`;

const OpacityOverlay = tw.div `absolute inset-0 bg-black opacity-25 h-screen`;

const SocialLinksContainer = tw.div ``;
const SocialLink = styled.a `
  ${tw`cursor-pointer inline-block text-6xl rounded-full p-3 bg-black hover:bg-white text-white hover:text-black transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const Heading = styled.h1`
  ${tw`text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-black text-white border-white rounded-full leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-black font-bold shadow transition duration-300 text-white hocus:bg-white hocus:text-black focus:outline-none focus:shadow-outline`;

//Hero IMGS
const images = [
	{
		name: "Amsterdan",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Amsterdan.jpg",
	},
	{
		name: "Bhargava Marripati",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Bhargava_Marripati.jpg",
	},
	{
		name: "Cancun",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Cancun.jpg",
	},
	{
		name: "Cancun",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Cancun1.jpg",
	},
	{
		name: "Costa Rica",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Costa_Rica.jpg",
	},
	{
		name: "Costa Rica",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Costa_Rica1.jpg",
	},
	{
		name: "Dubai",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Dubai.jpg",
	},
	{
		name: "Dubai",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Dubai1.jpg",
	},
	{
		name: "Dubai",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Dubai2.jpg",
	},
	{
		name: "Maldives",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Maldives.jpg",
	},
	{
		name: "Maldives",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Maldives1.jpg",
	},
	{
		name: "Marrakech",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Orlando.jpg",
	},
	{
		name: "Orlando",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Orlando.jpg",
	},
	{
		name: "Orlando",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Orlando1.jpg",
	},
	{
		name: "Republica Dominicana",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/R_Dominicana.jpg",
	},
	{
		name: "Riu",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Riu.jpg",
	},
	{
		name: "Roma",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Roma.jpg",
	},
	{
		name: "Roma",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Roma1.jpg",
	},
	{
		name: "Las Vegas",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Vegas.jpg",
	},
	{
		name: "Las Vegas",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Vegas1.jpg",
	},
	{
		name: "Las Vegas",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Vegas2.jpg",
	},
	{
		name: "Las Vegas",
		text: "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel.",
		url: "https://1goodtravel.s3.amazonaws.com/hero_images/Vegas3.jpg",
	},
]

export default ({t}) => {
	const [white, setWhite] = useState(true)
	return (
		<Container id="hero">
			<HeroCarousel interval={3000}>

				{images.map(image => (
					<div>
						<img className="img-hero" src={image.url} style={{ position: "absolute", maxHeight: "100%", width: "100%" }} />
						<OpacityOverlay />
						<Column>
							<HeroContainer>
								<Content>
									{/*Info Hero*/}
									<div className="text-hero">
										<Heading>
											{image.name}
										</Heading>
										<p style={{ color: "white", fontSize: "24px", width: "50%" }}>{t("hero.text")}</p>
										<Link to="/searchs"><PrimaryAction>{t("hero.button")}</PrimaryAction></Link>
									</div>
									{/*Social Links*/}
									<div className="hero-social">
										<SocialLinksContainer>
											<SocialLink target="blank" href="https://www.tiktok.com/@one.goodtravel?is_from_webapp=1&sender_device=pc" onMouseEnter={() => setWhite(false)} onMouseLeave={() => setWhite(true)}>
												{white && <img src={TiktokIconB} style={{ width: "18px" }} />}
												{!white && <img src={TiktokIconN} style={{ width: "18px" }} />}
											</SocialLink>
											<SocialLink target="blank" href="https://twitter.com/onegoodtravel">
												<TwitterIcon />
											</SocialLink>
											<SocialLink target="blank" href="https://www.instagram.com/one.goodtravel/">
												<InstagramIcon />
											</SocialLink>
											<SocialLink target="blank" href="https://youtube.com/channel/UCgfeL5pExMKw9YXNX4djQSQ">
												<YoutubeIcon />
											</SocialLink>
											<SocialLink target="blank" href="https://facebook.com/1goodtravel">
												<FacebookIcon />
											</SocialLink>
										</SocialLinksContainer>
									</div>
								</Content>
							</HeroContainer>
						</Column>
					</div>
					
				))}

			</HeroCarousel>
		</Container>
		
	);
};