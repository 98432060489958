import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config.js";

//CSS Packages
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "../Components/Layouts.js";

//Icons
import { ReactComponent as EditIcon } from "feather-icons/dist/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "feather-icons/dist/icons/trash-2.svg";
import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check-square.svg";
import { ReactComponent as CancelIcon } from "feather-icons/dist/icons/x-square.svg";


//CSS Classes
const Table = tw.table``;
const THead = tw.thead``;
const TBody = tw.tbody``;
const Tr = tw.tr``;
const Th = tw.th`bg-gray-700`;
const Td = tw.td``;

const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const Select = tw.select`w-full lg:w-11/12 mx-auto px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;

export default () => {
    //Loged Account
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers()
    }, []);

    //Accounts List
    async function getUsers() {
        const result = await axios.get(`${API_URL}/admin/users`, {
            headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
        });
        setUsers(result.data.accounts)
    }

    return (
        <Container style={{ margin: "auto", textAlign: "center", overflowX: "scroll" }}>

            {/*Account Table*/}
            <Table id="table">
                <THead>
                    <Th>Email</Th>
                    <Th>Name</Th>
                    <Th>Phone</Th>
                    <Th>Role</Th>
                    <Th>Actions</Th>
                </THead>
                <TBody>
                    {users.map(user => (
                        <User user={user} getUsers={getUsers} />
                    ))}
                </TBody>
            </Table>
        </Container>
    );
};

const User = ({ user, getUsers }) => {
    //Account Info
    const [fullName, setFullName] = useState(user.fullName)
    const [email, setEmail] = useState(user.email)
    const [phone, setPhone] = useState(user.phone)
    const [role, setRole] = useState(user.role)

    //Active when deleting or editing an account
    const [remove, setRemove] = useState(false)
    const [edit, setEdit] = useState(false)

    //Chane status
    const handleSetRemove = () => {
        setRemove(true)
        setEdit(false)
    }
    const handleSetEdit = () => {
        setRemove(false)
        setEdit(true)
    }
    const handleCancel = () => {
        setRemove(false)
        setEdit(false)
    }


    //Remove Account
    const handleRemove = () => {
        axios.post(`${API_URL}/admin/users/delete`, { email }, { headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` } }).
            then(res => {
                handleCancel()
                getUsers()
            }).
            catch(err => console.log(err));
    }
    //Edit Account
    const handleEdit = () => {
        axios.post(`${API_URL}/admin/users/update`, {
            fullName,
            email,
            phone,
            role
        }, { headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` } }).
            then(res => {
                handleCancel()
                getUsers()
            }).
            catch(err => console.log(err));
    }

    return (
        <Tr>
            <Td>{user.email}</Td>

            {/*Account Info. if edit is active => input to change*/}
            {!edit && <Td>{user.fullName}</Td>}
            {edit && <Td><Input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} /> </Td>}
            {!edit && <Td>{user.phone}</Td>}
            {edit && <Td><Input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} /> </Td>}
            {!edit && <Td>{user.role}</Td>}
            {edit && <Td><Select onChange={(e) => setRole(e.target.value)}>
                <option value="Account">Account</option>
                <option value="Admin">Admin</option>
            </Select> </Td>}

            {/*Remove active*/}
            {remove && <Td>
                <p>Are your shure
                    <CheckIcon onClick={handleRemove} className="icon" />
                    <CancelIcon onClick={handleCancel} className="icon" />
                </p>
            </Td>}

            {/*Edit active*/}
            {edit && <Td>
                <CheckIcon onClick={handleEdit} className="icon" />
                <CancelIcon onClick={handleCancel} className="icon" />
            </Td>}
            
            {/*Remove and Edit inactive => options*/}
            {!remove && !edit && <Td>
                <EditIcon onClick={handleSetEdit} className="icon" />
                <DeleteIcon onClick={handleSetRemove} className="icon" />
            </Td>}
        </Tr>
    )
}