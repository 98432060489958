import React from"react"

import { useTranslation } from "react-i18next"
import Iframe from "react-iframe"

import Header from "./Header.js"
import Footer from "../Components/Footer.js"

export default () => {
      const [t, i18n] = useTranslation("global")

     return (
         <>
         
           <Header t={t} i18n={i18n} />
           <br/>
           <br/>
           <br/>
           <br/>
              <div style={{ paddingTop: "67px"}}>
               <Iframe 
                url = "https://www.mundigeaonline.com/paquetes/index.php?nocabecera=1&idAgencia=41144&idAgente=94274&codIframe=215c4bf03b6eb1fe0eec09b5d03462f1"
                width = "100%"
                height = "850px"
                display = "initial"
                
                styles={{marginTop: "500px"}}/>
                </div>
             
             <Footer t={t} /> 
       </>
	);
}


