import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom"
import axios from "axios";
import { API_URL } from "../../config.js";

//CSS Package
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

//Components
import AnimationRevealPage from "../Components/AnimationRevealPage.js";
import { Container as ContainerBase } from "../Components/Layouts";
import { ReactComponent as KeyIcon } from "feather-icons/dist/icons/key.svg";

import illustration from "assets/images/login-illustration.svg";
import logo from "assets/images/Logo/last1.png";

//CSS Classes
const Container = tw(ContainerBase)
    `min-h-screen bg-blue-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-20 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-blue-500 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-blue-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default (props) => {

    const [error, setError] = useState(false);
    const [resetError, setResetError] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [access, setAccess] = useState(false)

    const [account, setAccount] = useState()
    const [validation, setValidation] = useState()

    useEffect(async () => {
        const search = props.history.location.search
        if (search) {
            try {
                const line = search.split("?");
                const params = line[1].split("&")
                const val = params[0].split("=")
                const account = params[1].split("=")

                const valName = val[0]
                const valValue = val[1]

                if (valName != "id")
                    setError(true)

                const accountName = account[0]
                const accountValue = account[1]

                if (accountName != "accountId")
                    setError(true)

                setAccount(accountValue)
                setValidation(valValue)
            }
            catch (err) {
                setError(true)
            }
        }
        else
            setError(true)
    }, []);

    //Account info
    const [password, setPassword] = useState("")
    const [rPassword, setRPassword] = useState("")

    const handleReset = e => {
        e.preventDefault();
        if (redirect) {
            setAccess(true)
            return;
        }
        let _error = ""

        if (password != rPassword) _error = "Password and Repeat Password don't match";
        if (!rPassword) _error = "Please enter a valid repeat password";
        if (!password) _error = "Please enter a valid password";

        setResetError(_error)
        if (_error) return

        const data = {
            id: validation,
            accountId: account,
            password: password
        };

        axios.post(`${API_URL}/account/change_pass`, data)
            .then(res => {
                if (res.data.error)
                    setResetError(res.data.error)
                else {
                    setResetError('')
                    setRedirect(true)
                }
            })
            .catch(err => {
                console.log(err)
                setResetError("Please try again")
            })

    }

    if (error) return <Redirect to="/" />
    if (access) return <Redirect to="/login" />

    return (
        <AnimationRevealPage>
            <Container>
                <Content>
                    <MainContainer>
                        <LogoLink href="/">
                            <LogoImage src={logo} />
                        </LogoLink>
                        <MainContent tw="text-center">
                            <Heading>Reset 1Good Travel Password</Heading>
                            <FormContainer>
                                <Form onSubmit={handleReset}>
                                    <Input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                                    <Input value={rPassword} onChange={(e) => setRPassword(e.target.value)} type="password" placeholder="Repeat Password" />

                                    <p style={{ color: 'red', textAlign: 'center', marginTop: "10px" }}>{resetError}</p>
                                    {redirect && <p style={{ color: 'blue', textAlign: 'center', marginTop: "10px" }}>Your password has been changed successfully</p>}

                                    <SubmitButton type="submit">
                                        <KeyIcon className="icon" />
                                        {!redirect && <span className="text">Reset</span>}
                                        {redirect && <span className="text">Access</span>}
                                    </SubmitButton>
                                </Form>
                            </FormContainer>
                        </MainContent>
                    </MainContainer>
                    <IllustrationContainer>
                        <IllustrationImage imageSrc={illustration} />
                    </IllustrationContainer>
                </Content>
            </Container>
        </AnimationRevealPage>
    );
}