import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config.js";
import { Redirect, Link } from "react-router-dom"

//CSS PAckages
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";


import { SectionHeading } from "../Components/Headings";
import { getCurrentUser } from "../../services/account";

//Icons
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as KeyIcon } from "feather-icons/dist/icons/key.svg";
import { ReactComponent as DollarIcon } from "feather-icons/dist/icons/dollar-sign.svg";

//CSS Classes
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)
    `text-gray-900`;

const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white m-1`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-blue-500 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const Table = tw.table``;
const THead = tw.thead``;
const TBody = tw.tbody``;
const Tr = tw.tr``;
const Th = tw.th`bg-blue-900`;
const Td = tw.td``;




export default ({ user, setUser, t }) => {
    const [reserved, setReserved] = useState([])
    useEffect(async () => {
        const _reserved = await getReserved();
        setReserved(_reserved)
    }, []);

    async function getReserved() {
        const result = await axios.get(`${API_URL}/account/getReserved`, {
            headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
        });
        return result.data.reserved
    }

    const [error, setError] = useState("")
    const [errorType, setErrorType] = useState("")
    const [updated, setUpdated] = useState("")

    const [name, setName] = useState(user.fullName);
    const [phone, setPhone] = useState(user.phone);

    const [current, setCurrent] = useState("");
    const [password, setPassword] = useState("");
    const [repeat, setRepeat] = useState("");

    const handleChangeProfile = (e) => {
        e.preventDefault();
        let error = ""
        if (!name || !phone) error = "Please fill all fields"

        if (error != "") {
            setErrorType("Profile")
            setError(error);
            return
        }

        axios.post(`${API_URL}/account/update`, { name, phone }, {
            headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
        }).
            then(res => {
                if (res.data.error) {
                    setErrorType("Profile")
                    setError(res.data.error)
                    setUpdated("")
                }
                else {
                    setError("")
                    setErrorType("")
                    setUpdated("Profile")
                    setUser(res.data.account)
                }
            }).
            catch(err => setError("Please try again"));
        }
        
        const handleChangePass = (e) => {
            e.preventDefault();
            let error = ""
            if (!current || !password || !repeat) error = "Please fill all fields"
            if (password != repeat) error = "Password and Repeat Password don't match"
            
            if (error != "") {
                setErrorType("Password")
                setError(error);
                return
            }
            
            axios.post(`${API_URL}/account/update_pass`, { current, password }, {
            headers: { Authorization: `bearer ${localStorage.getItem("1good_travel_account_token")}` }
        }).
            then(res => {
                if (res.data.error) {
                    setError(res.data.error)
                    setUpdated("")
                    setErrorType("Password")
                }
                else {
                    setError("")
                    setErrorType("")
                    setUpdated("Password")
                    setUser(res.data.account)
                }
            }).
            catch(err => setError("Please try again"));
    }
    return (
        <>
            <HeadingRow>
                <Heading>{user.fullName}</Heading>
            </HeadingRow>

            <div tw="w-5/6 m-auto mt-10">
                <HeadingRow>
                    <Heading tw="text-3xl">{t("profile.personal")}</Heading>
                </HeadingRow>

                <FormContainer>
                    <Form onSubmit={handleChangeProfile}>
                        <div tw="flex flex-col">
                            <div tw="md:flex w-full m-auto">
                                <Input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder={t("profile.name")} />
                                <Input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder={t("profile.phone")} />
                            </div>
                        </div>
                        <div tw="text-center">
                            {errorType == "Profile" && <p tw="text-red-500">{error}</p>}
                            {updated == "Profile" && <p tw="text-green-500">Profile Updated</p>}
                        </div>
                        <SubmitButton>
                            <UserIcon className="icon" />
                            <span className="text">{t("profile.update")}</span>
                        </SubmitButton>
                    </Form>
                </FormContainer>
            </div>

            <div tw="w-5/6 m-auto mt-10!">
                <HeadingRow>
                    <Heading tw="text-3xl">{t("profile.change")}</Heading>
                </HeadingRow>

                <FormContainer>
                    <Form onSubmit={handleChangePass}>
                        <div tw="flex flex-col">
                            <div tw="md:flex w-full m-auto">
                                <Input value={current} onChange={(e) => setCurrent(e.target.value)} type="text" placeholder={t("profile.current")} />
                                <Input value={password} onChange={(e) => setPassword(e.target.value)} type="text" placeholder={t("profile.password")} />
                                <Input value={repeat} onChange={(e) => setRepeat(e.target.value)} type="text" placeholder={t("profile.repeat")} />
                            </div>
                        </div>
                        <div tw="text-center">
                            {errorType == "Password" && <p tw="text-red-500">{error}</p>}
                            {updated == "Password" && <p tw="text-green-500">Password Updated</p>}
                        </div>
                        <SubmitButton>
                            <KeyIcon className="icon" />
                            <span className="text">{t("profile.change")}</span>
                        </SubmitButton>
                    </Form>
                </FormContainer>
            </div>

            <div tw="w-5/6 m-auto mt-10!">
                <HeadingRow>
                    <Heading tw="text-3xl">{t("profile.package")}</Heading>
                </HeadingRow>

                {reserved && <div>

                    {reserved.map(r => (
                        <div tw="mt-10">
                            <p tw="text-2xl">{r.location}: {r.name} - ${r.price} </p>
                            <br />
                            <Table id="table">
                                <THead>
                                    <Th>{t("checkout.name")}</Th>
                                    <Th>{t("checkout.middleName")}</Th>
                                    <Th>{t("checkout.lastName")}</Th>
                                    <Th>{t("checkout.country")}</Th>
                                </THead>
                                <TBody>
                                    {r.persons.map(p => (
                                        <Tr>
                                            <Td>{p.name}</Td>
                                            <Td>{p.middleName}</Td>
                                            <Td>{p.lastName}</Td>
                                            <Td>{p.country}</Td>
                                        </Tr>

                                    ))}
                                </TBody>
                            </Table>
                        </div>

                    ))}
                </div>}
            </div>
        </>
    );
};