import React from "react";
import { useTranslation } from "react-i18next"

//CSS Packages
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

//Components
import AnimationRevealPage from "../Components/AnimationRevealPage.js";
import Header from "./Header.js";
import Footer from "../Components/Footer.js";
import MainFeature1 from "../Components/TwoColWithButton.js";

//IMGS About
// import about_1 from "../../assets/images/About/about_1.jpg"
// import about_2 from "../../assets/images/About/about_2.jpg"
// import about_3 from "../../assets/images/About/about_3.jpg"
// import about_4 from "../../assets/images/About/about_4.jpg"
// import about_5 from "../../assets/images/About/about_5.jpg"
// import about_6 from "../../assets/images/About/about_6.jpg"
// import about_7 from "../../assets/images/About/about_7.jpg"
// import about_8 from "../../assets/images/About/about_8.jpg"

// import about_1 from "https://1goodtravel.s3.amazonaws.com/hero_images/Roma.jpg"
// import about_2 from "https://1goodtravel.s3.amazonaws.com/hero_images/Costa_Rica.jpg"
// import about_3 from "https://1goodtravel.s3.amazonaws.com/hero_images/R_Dominicana.jpg"
// import about_4 from "https://1goodtravel.s3.amazonaws.com/hero_images/Roma1.jpg"
// import about_5 from "https://1goodtravel.s3.amazonaws.com/hero_images/Orlando.jpg"
// import about_6 from "https://1goodtravel.s3.amazonaws.com/hero_images/Dubai.jpg"
// import about_7 from "https://1goodtravel.s3.amazonaws.com/hero_images/Maldives.jpg"
// import about_8 from "https://1goodtravel.s3.amazonaws.com/hero_images/Vegas3.jpg"

export default () => {
	const [t, i18n] = useTranslation("global")
	return (
		<AnimationRevealPage>
			<Header t={t} i18n={i18n}/>

			{/*Abouts*/}
			<div style={{paddingTop: "75px"}}>
			<MainFeature1
				heading={t("about.about.title")}
				description={t("about.about.text-1")}
				description1={t("about.about.text-2")}
				primaryButtonText={t("about.about.button")}
				buttonRounded={false}
				imageSrc="https://1goodtravel.s3.amazonaws.com/hero_images/Roma.jpg"
				textOnLeft={false}
			/>
			<MainFeature1
				heading={t("about.services.title")}
				description={t("about.services.text-1")}
				primaryButtonText={t("about.services.button")}
				buttonRounded={false}
				imageSrc="https://1goodtravel.s3.amazonaws.com/hero_images/Costa_Rica.jpg"
			/>
			<MainFeature1
				heading={t("about.trips.title")}
				description={t("about.trips.text-1")}
				primaryButtonText={t("about.trips.button")}
				buttonRounded={false}
				imageSrc="https://1goodtravel.s3.amazonaws.com/hero_images/R_Dominicana.jpg"
				textOnLeft={false}
			/>
			<MainFeature1
				heading={t("about.prices.title")}
				description={t("about.prices.text-1")}
				description1={t("about.prices.text-2")}
				primaryButtonText={t("about.prices.button")}
				buttonRounded={false}
				imageSrc="https://1goodtravel.s3.amazonaws.com/hero_images/Roma1.jpg"
			/>
			<MainFeature1
				heading={t("about.security.title")}
				description={t("about.security.text-1")}
				primaryButtonText={t("about.security.button")}
				buttonRounded={false}
				imageSrc="https://1goodtravel.s3.amazonaws.com/hero_images/Orlando.jpg"
				textOnLeft={false}
			/>
			<MainFeature1
				heading={t("about.quality.title")}
				description={t("about.quality.text-1")}
				primaryButtonText={t("about.quality.button")}
				buttonRounded={false}
				imageSrc="https://1goodtravel.s3.amazonaws.com/hero_images/Dubai.jpg"
			/>
			<MainFeature1
				heading={t("about.products.title")}
				description={t("about.products.text-1")}
				primaryButtonText={t("about.products.button")}
				buttonRounded={false}
				imageSrc="https://1goodtravel.s3.amazonaws.com/hero_images/Maldives.jpg"
				textOnLeft={false}
			/>
			<MainFeature1
				heading={t("about.policy.title")}
				description={t("about.policy.text-1")}
				primaryButtonText={t("about.policy.button")}
				buttonRounded={false}
				imageSrc="https://1goodtravel.s3.amazonaws.com/hero_images/Vegas3.jpg"
			/>
			</div>
			<Footer t={t}/>
		</AnimationRevealPage>
	);
};
