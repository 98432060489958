import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom"
import { API_URL } from "../../config.js";
import { useTranslation } from "react-i18next"

//CSS Packages
import { motion } from "framer-motion";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

//Components
import { Container, ContentWithPaddingXl } from "../Components/Layouts.js";
import { SectionHeading } from "../Components/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../Components/Buttons.js";

//Icons
import { ReactComponent as SvgDecoratorBlob1 } from "assets/images/Icons/svg-decorator-blob-2.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "assets/images/Icons/svg-decorator-blob-7.svg";
import { ReactComponent as CalendarIcon } from "feather-icons/dist/icons/calendar.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

import OrlandoIMG from "../../assets/images/Tourist Packages/Orlando/6.jpg";

//CSS Classes
const DecoratorBlob1 = styled(SvgDecoratorBlob1)
`
${tw`pointer-events-none absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
${tw`pointer-events-none absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;


const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)
    ``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-blue-500! text-gray-100!`}
  }
`;


const TabContent = tw(motion.div)`mt-6 flex flex-wrap flex-col md:flex-row sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12 m-auto`;
const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs mb-20`;
const CardImage = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`h-64 bg-cover bg-center rounded`
]);
const CardText = tw.div`mt-4`;
const CardHeader = tw.div`flex justify-between items-center`;
const CardType = tw.div`text-blue-700 font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;
const CardMeta = styled.div`
${tw`flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;
const CardMetaFeature = styled.div`
${tw`flex items-center mt-4`}
svg {
    ${tw`w-5 h-5 mr-1`}
  }
  `;
const CardAction = tw(PrimaryButtonBase)`w-full mt-8`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;

const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
svg {
    ${tw`w-6 h-6`}
}
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const HighlightedText = tw.span`bg-blue-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;


export default ({ t, i18n}) => {

    //Package and Locations List
    useEffect(async () => {

        const _packages = await getPackages();
        setPackages(_packages)

        const _tabNames = await getPackagesTypes()
        setTabsNames(_tabNames)

        const _tabs = getTabs(_packages, _tabNames, 0)
        setTabs(_tabs)

        const _keys = Object.keys(_tabs);
        setTabsKeys(_keys);
        setActiveTab(_keys[0])

    }, []);

    async function getPackages() {
        const result = await axios.get(`${API_URL}/package/getAll`);
        return result.data.packages
    }
    async function getPackagesTypes() {
        const result = await axios.get(`${API_URL}/package/getAllTypes`);
        return result.data.packageTypes
    }

    const [packages, setPackages] = useState([]);
    const [tabs, setTabs] = useState({});
    const [tabNames, setTabsNames] = useState();
    const [tabsKeys, setTabsKeys] = useState();
    const [activeTab, setActiveTab] = useState();
    const [tabsI, setTabsI] = useState(0);

    //Tabs to change location package
    const getTabs = (_packages, _tabNames, _tabsI) => {
        const _tabs = {
            All: getAllCards(_packages)
        }
        for (let i = _tabsI; i < _tabsI + 2; i++) {
            _tabs[_tabNames[i].name] = getCards(_packages, _tabNames[i].name)
        }
        return _tabs
    }
    const changeTabs = (dir) => {
        let _tabsI
        if (dir == 1) _tabsI = Math.min(tabsI + 2, tabNames.length - 2)
        if (dir == -1) _tabsI = Math.max(tabsI - 2, 0)

        const _tabs = getTabs(packages, tabNames, _tabsI)
        const _keys = Object.keys(_tabs);

        setTabsI(_tabsI)
        setTabs(_tabs)
        setTabsKeys(_keys);
        setActiveTab(_keys[0])
    }

    //Get all package
    const getAllCards = (_packages) => {
        return _packages.sort((p1, p2) => Number(p1.price) < Number(p2.price));
    }
    //Get package of a location
    const getCards = (_packages, tab) => {
        return _packages.filter(c => c.type == tab).sort((p1, p2) => Number(p1.price) < Number(p2.price));
    }

    //Given a package returns the range of avaliable dates
    const getFormatDate = p => {

        const startUTC = new Date(p.startDate);
        const startNormal = startUTC.toISOString();
        const start = new Date(startNormal.substring(0, startNormal.length - 1));

        const endUTC = new Date(p.endDate);
        const endNormal = endUTC.toISOString();
        const end = new Date(endNormal.substring(0, endNormal.length - 1));



        const startM = start.toLocaleString("en-US", { month: "short" })
        const endM = start.toLocaleString("en-US", { month: "short" })


        const startR = startM + " " + start.getDate() + ", " + start.getFullYear();
        const endR = endM + " " + end.getDate() + ", " + end.getFullYear();

        return startR + " - " + endR
    }

    return (
        <Container id="packages">
            <ContentWithPaddingXl>
                <HeaderRow>
                    <Header><HighlightedText>{t("package-home.fName")} </HighlightedText>{t("package-home.sName")}.</Header>
                    <Controls>
                        <PrevButton onClick={() => changeTabs(-1)}><ChevronLeftIcon /></PrevButton>
                    </Controls>
                    <TabsControl>
                        {Object.keys(tabs).map((tabName, index) => (
                            <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                                {tabName}
                            </TabControl>
                        ))}
                    </TabsControl>
                    <Controls>
                        <NextButton onClick={() => changeTabs(1)}><ChevronRightIcon /></NextButton>
                    </Controls>
                </HeaderRow>

                {tabsKeys && tabsKeys.map((tabKey, index) => (
                    <>
                        {activeTab == tabKey && <TabContent
                            key={index}
                            transition={{ duration: 0.4 }}
                        >
                            {tabs[tabKey].map((p, index) => (
                                <CardContainer key={index}>
                                    <Card>
                                        <CardImage imageSrc={p.image ? `https://1goodtravel.s3.amazonaws.com/${p.image}` : OrlandoIMG} />
                                        <CardText>
                                            <CardHeader>
                                                <CardType>{p.name}</CardType>
                                                <CardPrice>
                                                    <CardPriceAmount>{p.price}</CardPriceAmount>
                                                </CardPrice>
                                            </CardHeader>
                                            <CardMeta>
                                                <CardMetaFeature>
                                                    <TimeIcon /> {p.numberDays} {t("package-home.night")}
                                                </CardMetaFeature>
                                                <CardMetaFeature>
                                                    <CalendarIcon /> {getFormatDate(p)}
                                                </CardMetaFeature>
                                            </CardMeta>
                                            <Description style={{ textAlign: "start" }}>{p.description.substring(0,47)}....</Description>
                                            <Link to={`/details?package=${p._id}`} style={{ cursor: "pointer" }}><CardAction>{t("package-home.details")}</CardAction></Link>
                                        </CardText>
                                    </Card>
                                </CardContainer>
                            ))}
                        </TabContent>}
                    </>
                ))}
            </ContentWithPaddingXl>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );

};