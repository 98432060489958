import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAdmin } from "../../services/account";

export default function AdminRoute({ component: Component, ...rest }) {
    const [user, setUser] = useState("");

    useEffect(() => {
        async function getUser() {
            const _user = await getAdmin();
            setUser(_user);
        }
        getUser();
    }, []);

    if (!user) return null;

    return ( <
        Route {...rest }
        render = {
            props => (
                user ?
                <
                Component user = { user } {...props }
                /> :
                < Redirect to = '/login' / >
            )
        }
        />
    );
};