import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { useEffect } from "react";
import { Helmet} from "react-helmet";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AdminRoute from 'pages/Components/AdminRoute.js'
import PrivateRoute from 'pages/Components/PrivateRoute.js'

import HomePage from "pages/Home/Index.js"
import Details from "pages/Details/Index.js"
import Searchs from "pages/Searchs/Index.js"
import Hotels from "pages/Hotels/Index.js"
import Cruise from "pages/Cruise/Index.js"
import Package from "pages/Package/Index.js"
import Train_hotel from "pages/Train_hotel/Index.js"
import Flight_hotel from "pages/Flight_hotel/Index.js"


import SignIn from "pages/Login/SignIn.js"
import SignUp from "pages/Login/SignUp.js"
import Logout from "pages/Login/Logout.js"
import Validate from "pages/Login/Validate.js"
import Forget from "pages/Login/Forget.js"
import Reset from "pages/Login/Reset.js"

import AboutUs from "pages/Privacy/AboutUs.js"
import Term from "pages/Privacy/TermsOfService.js"

import Profile from "pages/Profile/Index.js"
import Admin from "pages/Admin/Index.js"



export default function App() {

	return ( 
	<>
	   <Helmet>
        <meta charSet="utf-8" />
        <title>OneGoodTravel</title>
        <meta name="description" content="travel agency" />
        <meta name="keywords" content="vacation , travel, cuba , varadero , vuelos , fligth , Hotel , hotels , viajes , Europa , Orlando , pasajes , paquetes turisticos , reservas , cruise , cruceros , caceria , hunter" />
      </Helmet>
	   
		<Router>
			<Switch>				
				<Route exact path="/"> <HomePage /> </Route>

				<Route exact path="/register"><SignUp /></Route>
				<Route exact path="/login"><SignIn /></Route>
				<Route exact path="/logout"><Logout /></Route>

				<Route exact path="/validate" component={Validate} />
				<Route exact path="/forget" component={Forget} />
				<Route exact path="/reset" component={Reset} />

				<Route exact path="/about"><AboutUs /></Route>
				<Route exact path="/hotels"><Hotels /></Route>
				<Route exact path="/cruise"><Cruise /></Route>
				<Route exact path="/package"><Package /></Route>
				<Route exact path="/train_hotel"><Train_hotel /></Route>
				<Route exact path="/flight_hotel"><Flight_hotel /></Route>
				<Route exact path="/term"><Term /></Route>

				<Route exact path="/details" component={Details} />
				<Route exact path="/searchs" component={Searchs} />

				<PrivateRoute component={Profile} path="/profile" exact />
				<AdminRoute component={Admin} path="/admin" exact />
			</Switch>
		</Router>
	</>
	);
}	
